import { makeStyles } from '@material-ui/core'
import { useLayoutEffect, useState } from 'react'
import { SITE, TRANSACTION } from '../../apis/urlConfig'
import SelectCustom from '../../components/select_custom'
import TableCustom from '../../components/table'
import { COLUMN_TABLE_PAYMENT } from '../../constants/column'
import {
  IS_ACTIVE,
  PAYMENT_METHOD,
  TYPE_TRANSACTION,
} from '../../types/enum'
import { useAppSelector } from '../../app/hooks'
import { selectListData } from '../../feature/global/globalSlice'
import { GlobalType } from '../../types/global.type'

const useStyles = makeStyles({
  payment_management_container: {
    '&>div:nth-of-type(1)': {
      margin: '2rem 0 1rem 0',
      display: 'flex',
      justifyContent: 'space-between',
      '&>div': {
        display: 'flex',
      },
    },
    '&>p': {
      display: 'flex',
      fontSize: '18px',
      fontWeight: 500,
      '&>span': {
        width: '28px',
        height: '28px',
        display: 'flex',
        background: '#2B83FE',
        borderRadius: '100px',
        justifyContent: 'center',
        fontSize: '14px',
        fontWeight: 500,
        color: 'white',
        alignItems: 'center',
        marginLeft: '8px',
      },
    },
  },
})

const PaymentManagement = () => {
  const classes = useStyles()
  const [paramsHeader, setParamsHeader] = useState<{
    _sort: string
    typeTransaction?: TYPE_TRANSACTION
    isActive?: IS_ACTIVE
    paymentMethod?: PAYMENT_METHOD
  }>({
    _sort: 'createdAt@desc',
    paymentMethod: PAYMENT_METHOD.TRANSFER,
    isActive: IS_ACTIVE.PENDING,
  })
  const dataGlobal = useAppSelector(selectListData)
  console.log('params', paramsHeader)
  return (
    <div className={classes.payment_management_container}>
      <div>
        <div>
          <SelectCustom
            data={[
              { label: '정렬 기준: DESC', value: 'createdAt@desc' },
              { label: '정렬 기준: ASC', value: 'createdAt@asc' },
            ]}
            value={paramsHeader._sort}
            onChange={(e: any) => setParamsHeader({ ...paramsHeader, _sort: e })}
          />
          <SelectCustom
            data={[
              { label: '신용카드', value: 'CREDIT_CARD' },
              { label: '계좌이체', value: 'TRANSFER' },
            ]}
            value={paramsHeader.paymentMethod}
            onChange={(e: any) =>
              setParamsHeader({ ...paramsHeader, paymentMethod: e })
            }
          />
          <SelectCustom
            data={[
              { label: '확인중', value: 'false' },
              { label: '완료', value: 'true' },
            ]}
            value={paramsHeader.isActive}
            onChange={(e: any) => setParamsHeader({ ...paramsHeader, isActive: e })}
          />
        </div>
      </div>
      <p>
      접수목록 
      {/* {dataGlobal.site > 0 && <span>{dataGlobal.site}</span>} */}
      </p>
      <TableCustom
        column={COLUMN_TABLE_PAYMENT}
        select
        action
        hideUpdate
        hideSee
        url={SITE}
        params={paramsHeader}
      />
    </div>
  )
}

export default PaymentManagement
