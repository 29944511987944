export const BASE_URL = 'https://server.gmapps.net/api/v1/admin'
export const DATA_TRANSACTION = [
  {
    _id: '631d9f32a65cf07250b8938c',
    title: '020플랫폼',
    value: 91148,
    typeTransaction: 'deposit',
    description: 'Direct',
    site: {
      _id: '631d9f32a65cf07250b8938c',
      name: '앱스토어 정보',
      adminEmail: 'Josianne_Hammes57@example.com',
      // androidInfo: {
      //   user: 'Peso',
      //   password: 'North',
      //   appName: 'Dynamic Response Supervisor',
      //   icon: 'https://loremflickr.com/512/512/icon',
      //   homeScreen: 'https://loremflickr.com/1440/2960/screen',
      //   notificationIcon: 'https://loremflickr.com/96/96/icon',
      // },
      iosInfo: {
        user: 'Cab',
        password: 'FTP',
        appName: 'Forward Division Producer',
        icon: 'https://loremflickr.com/512/512/icon',
        homeScreen: 'https://loremflickr.com/1440/2960/screen',
        contactInfo: 'Gloria_Huels@example.net',
        description: 'Global',
        keyword: 'generously',
        textColor: 'black',
        backgroundColor: '#fff',
      },
      // webInfo: {
      //   domainUser: 'Genderflux',
      //   domainName: 'bare-officer.com',
      //   domainPassword: 'pixel',
      //   awsUser: 'Dobra',
      //   awsPassword: 'Assurance',
      //   awsUrl: 'http://dishonest-down.com',
      //   favicon: 'https://loremflickr.com/512/512/icon',
      //   thumbnail: 'https://loremflickr.com/1440/2960/thumbnail',
      //   notificationIcon: 'https://loremflickr.com/96/96/notificationIcon',
      // },
      isActive: true,
      expirationDate: '2023-04-20T04:24:17.398Z',
      createdAt: '2023-04-19T19:09:54.127Z',
      updatedAt: '2023-04-20T06:11:53.636Z',
      creator: 'cae3f5b0-a5e3-4ec2-bfee-383f718f2938',
      theme: 'e4494691-9176-4f2a-8c65-971e195885da',
    },
    createdAt: '2023-04-18T20:01:19.411Z',
    updatedAt: '2023-04-19T12:25:53.612Z',
    payer: '251fe0d6-fa7b-4338-9468-245a199e6783',
    status: true,
  },
  {
    _id: '631d9f32a65cf07250b8938c',
    title: '020플랫폼',
    value: 191148,
    typeTransaction: 'deposit',
    description: 'Direct',
    site: {
      _id: '631d9f32a65cf07250b8938c',
      name: '사이트 생성 3개월',
      adminEmail: 'Josianne_Hammes57@example.com',
      // androidInfo: {
      //   user: 'Peso',
      //   password: 'North',
      //   appName: 'Dynamic Response Supervisor',
      //   icon: 'https://loremflickr.com/512/512/icon',
      //   homeScreen: 'https://loremflickr.com/1440/2960/screen',
      //   notificationIcon: 'https://loremflickr.com/96/96/icon',
      // },
      // iosInfo: {
      //   user: 'Cab',
      //   password: 'FTP',
      //   appName: 'Forward Division Producer',
      //   icon: 'https://loremflickr.com/512/512/icon',
      //   homeScreen: 'https://loremflickr.com/1440/2960/screen',
      //   contactInfo: 'Gloria_Huels@example.net',
      //   description: 'Global',
      //   keyword: 'generously',
      //   textColor: 'black',
      //   backgroundColor: '#fff',
      // },
      webInfo: {
        domainUser: 'Genderflux',
        domainName: 'bare-officer.com',
        domainPassword: 'pixel',
        awsUser: 'Dobra',
        awsPassword: 'Assurance',
        awsUrl: 'http://dishonest-down.com',
        favicon: 'https://loremflickr.com/512/512/icon',
        thumbnail: 'https://loremflickr.com/1440/2960/thumbnail',
        notificationIcon: 'https://loremflickr.com/96/96/notificationIcon',
      },
      isActive: true,
      expirationDate: '2023-04-20T04:24:17.398Z',
      createdAt: '2023-04-19T19:09:54.127Z',
      updatedAt: '2023-04-20T06:11:53.636Z',
      creator: 'cae3f5b0-a5e3-4ec2-bfee-383f718f2938',
      theme: 'e4494691-9176-4f2a-8c65-971e195885da',
    },
    createdAt: '2023-04-19T20:01:19.411Z',
    updatedAt: '2023-04-19T12:25:53.612Z',
    payer: '251fe0d6-fa7b-4338-9468-245a199e6783',
    status: false,
  },
  {
    _id: '631d9f32a65cf07250b8938c',
    title: '블로그형 홈페이지',
    value: 921148,
    typeTransaction: 'deposit',
    description: 'Direct',
    site: {
      _id: '631d9f32a65cf07250b8938c',
      name: '구글플레이 정보',
      adminEmail: 'Josianne_Hammes57@example.com',
      androidInfo: {
        user: 'Peso',
        password: 'North',
        appName: 'Dynamic Response Supervisor',
        icon: 'https://loremflickr.com/512/512/icon',
        homeScreen: 'https://loremflickr.com/1440/2960/screen',
        notificationIcon: 'https://loremflickr.com/96/96/icon',
      },
      // iosInfo: {
      //   user: 'Cab',
      //   password: 'FTP',
      //   appName: 'Forward Division Producer',
      //   icon: 'https://loremflickr.com/512/512/icon',
      //   homeScreen: 'https://loremflickr.com/1440/2960/screen',
      //   contactInfo: 'Gloria_Huels@example.net',
      //   description: 'Global',
      //   keyword: 'generously',
      //   textColor: 'black',
      //   backgroundColor: '#fff',
      // },
      // webInfo: {
      //   domainUser: 'Genderflux',
      //   domainName: 'bare-officer.com',
      //   domainPassword: 'pixel',
      //   awsUser: 'Dobra',
      //   awsPassword: 'Assurance',
      //   awsUrl: 'http://dishonest-down.com',
      //   favicon: 'https://loremflickr.com/512/512/icon',
      //   thumbnail: 'https://loremflickr.com/1440/2960/thumbnail',
      //   notificationIcon: 'https://loremflickr.com/96/96/notificationIcon',
      // },
      isActive: true,
      expirationDate: '2023-04-20T04:24:17.398Z',
      createdAt: '2023-04-19T19:09:54.127Z',
      updatedAt: '2023-04-20T06:11:53.636Z',
      creator: 'cae3f5b0-a5e3-4ec2-bfee-383f718f2938',
      theme: 'e4494691-9176-4f2a-8c65-971e195885da',
    },
    createdAt: '2023-04-19T20:01:19.411Z',
    updatedAt: '2023-04-19T12:25:53.612Z',
    payer: '251fe0d6-fa7b-4338-9468-245a199e6783',
    status: true,
  },
]
