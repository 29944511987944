import { makeStyles } from '@material-ui/core'
import Button from '@material-ui/core/Button'
import KeyboardBackspaceIcon from '@material-ui/icons/KeyboardBackspace'
import infoCircle from '../../asset/icons/info_circle.svg'
import questionCircle from '../../asset/icons/question_circle.svg'
import InputBase from '../../components/input'
import Upload from '../../components/upload'
import { useNavigate, useParams } from 'react-router-dom'
import { useEffect, useState } from 'react'
import { loadingActions } from '../../components/loading/loadingSlice'
import { DataWebType, SiteType } from '../../types/site.type'
import { SITE } from '../../apis/urlConfig'
import { useAppDispatch } from '../../app/hooks'
import axiosClient from '../../apis/axiosClient'
import { snackBarActions } from '../../components/snackbar/snackbarSlice'
import { STATUS_SITE_UPDATE } from '../../types/enum'
import { ROUTE } from '../../router/routes'
import { globalActions } from '../../feature/global/globalSlice'

const useStyle = makeStyles({
  web_info_container: {
    paddingBottom: '1rem',
    '&>p:nth-of-type(1)': {
      display: 'flex',
      fontSize: '18px',
      '&>span': {
        marginRight: '8px',
      },
    },
    '&>div:nth-of-type(1)': {
      marginTop: '1rem',
      padding: '24px',
      border: '1px solid #D0D5DD',
      borderRadius: '12px',
    },

    '&>div:nth-of-type(2)': {
      marginTop: '1rem',
      padding: '24px',
      border: '1px solid #D0D5DD',
      borderRadius: '12px',
      '&>div:nth-of-type(1)': {
        marginBottom: '1rem',
      },
      '&>div:nth-of-type(2)': {
        display: 'flex',
        '&>div': {
          width: '50%',
          '&>p:nth-of-type(1)': {
            fontWeight: 400,
            margin: 0,
            display: 'flex',
            alignItems: 'center',
            '&>img': {
              marginLeft: '5px',
            },
          },
          '&>p:nth-of-type(2)': {
            fontWeight: 400,
            fontSize: '14px',
            color: '#70777F',
            marginTop: '5px',
          },
        },
      },
      '&>div:nth-of-type(3)': {
        marginTop: '2rem',
        '&>p:nth-of-type(1)': {
          fontWeight: 400,
          margin: 0,
          display: 'flex',
          alignItems: 'center',
          '&>img': {
            marginLeft: '5px',
          },
        },
        '&>p:nth-of-type(2)': {
          fontWeight: 400,
          fontSize: '14px',
          color: '#70777F',
          marginTop: '5px',
        },
        '&>p:nth-of-type(3)': {
          fontWeight: 400,
          fontSize: '14px',
          '&>span': {
            color: '#FD3535',
          },
        },
        '&>p:nth-of-type(4)': {
          fontWeight: 400,
          fontSize: '14px',
          color: '#70777F',
          marginTop: '5px',
        },
      },
    },
    '&>div:nth-of-type(3)': {
      marginTop: '1rem',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      gap: '32px',
    }
  },
  title_div: {
    marginTop: 0,
    marginBottom: 5,
  },
})

const WebInfo = () => {
  const classes = useStyle()
  const { id } = useParams()
  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  const [site, setSite] = useState<SiteType>()

  console.log('Site Detail', site);

  const handleUpdateSite = async () => {
    try {
      dispatch(loadingActions.openLoading())
      const datPatch: { key: string } = {
        key: 'webInfo'
      }
      if (site?.webInfo?.status === STATUS_SITE_UPDATE.CREATED) {
        console.log('Site update succeed')
        dispatch(loadingActions.loadingSuccess())
        navigate(ROUTE.PROJECT_MANAGEMENT)
      }
      else {
        axiosClient.patch(`${SITE}/update/${id}/active-info`, datPatch)
          .then((res) => {
            console.log('Site update succeed:', res)
            dispatch(loadingActions.loadingSuccess())

            dispatch(snackBarActions.setStateSnackBar({
              content: '성공',
              type: 'success',
            }))
            dispatch(globalActions.getList({ params: undefined }))
            navigate(ROUTE.PROJECT_MANAGEMENT)
          })
          .catch((error: any) => {
            console.log(error)
            dispatch(loadingActions.loadingSuccess())
            dispatch(snackBarActions.setStateSnackBar({
              content: '실패',
              type: 'error',
            }))
          })
      }

    } catch (error) {
      console.log('error:', error)
      dispatch(loadingActions.loadingSuccess())
      dispatch(snackBarActions.setStateSnackBar({
        content: '실패',
        type: 'error',
      }))
    }
  }

  useEffect(() => {
    const getDetailConversation = async () => {
      try {
        dispatch(loadingActions.openLoading())
        const data: { data: SiteType } = await axiosClient.get(
          `${SITE}/get/${id}`
        )
        setSite(data.data)
        dispatch(loadingActions.loadingSuccess())
      } catch (error) {
        console.log(error)
        dispatch(loadingActions.loadingSuccess())
      }
    }
    getDetailConversation()
  }, [id])


  return (
    <div className={classes.web_info_container}>
      <p>
        <span onClick={() => navigate(ROUTE.PROJECT_MANAGEMENT)}>
          <KeyboardBackspaceIcon />
        </span>
        사이트 정보
      </p>

      <div>
        <p className={classes.title_div}>도메인 구입한 사이트</p>
        <InputBase
          label='도메인 회사'
          onChange={() => { }}
          placeholder='URL입력해주세요.'
          style={{ width: '656px', height: '44px' }}
          value={site?.webInfo?.domainName}
          disabled
        />
        <InputBase
          label='아이디'
          onChange={() => { }}
          placeholder='입력해주세요.'
          style={{ width: '656px', height: '44px' }}
          value={site?.webInfo?.domainUser}
          disabled
        />
        <InputBase
          label='비밀번호'
          onChange={() => { }}
          placeholder='입력해주세요.'
          style={{ width: '656px', height: '44px' }}
          value={site?.webInfo?.domainPassword}
          disabled
        />
        <InputBase
          label='구입한 도메인'
          onChange={() => { }}
          placeholder='URL입력해주세요.'
          style={{ width: '656px', height: '44px' }}
          value={site?.webInfo?.domainProvider}
          disabled
        />
      </div>

      <div>
        <InputBase
          label='사이트 이름'
          onChange={() => { }}
          placeholder='입력해주세요.'
          style={{ width: '656px', height: '44px' }}
          value={site?.name}
          disabled
        />
        <p className={classes.title_div}>그래픽 저작물</p>
        <div>
          <div>
            <p>
              패비콘{' '}
              <img src={questionCircle} alt='' />
            </p>
            <p>고해상도 아이콘: 512x512 / 32비트 PNG(알파 있음)</p>
            <Upload style={{ width: '358px', height: '358px' }} img={site?.webInfo?.favicon} />
          </div>
          <div>
            <p>
              시작화면 (선택사항) <img src={questionCircle} alt='' />
            </p>
            <p>가로x세로 1440x2960 JPG또는 24비트 PNG(알파 없음)</p>
            <Upload style={{ width: '175px', height: '358px' }} img={site?.webInfo?.thumbnail} />
          </div>
        </div>
        <div>
          <p>
            알림 아이콘(선택사항) <img src={questionCircle} alt='' />
          </p>
          <p>권장 해상도: 96x96 / PNG / 배경 투명으로, 흰색으로 표현</p>
          <p>
            <span>안내:</span> 안드로이드 5.0 이상부터 유채색 알림 아이콘을 지원하지 않아 단색 아이콘을 첨부하셔야 합니다. 자세히 알아보기
          </p>
          <Upload
            style={{ width: '96px', height: '96px' }}
            textButton='PNG 파일 업로드'
            img={site?.webInfo?.notificationIcon}
          />
          <p>
            *알림 아이콘은 앱에서 알림이 왔을때 상단에 보여지는 아이콘입니다.
          </p>
        </div>
      </div>
      <div>
        <Button
          variant='outlined'
          style={{ borderRadius: 0, fontSize: '16px', textTransform: 'none' }}
          onClick={() => navigate(ROUTE.PROJECT_MANAGEMENT)}
        >
          Cancel
        </Button>
        <Button
          variant='contained'
          color='primary'
          style={{ borderRadius: 0, fontSize: '16px', textTransform: 'none' }}
          onClick={handleUpdateSite}
        >
          Updated
        </Button>
      </div>
    </div>
  )
}

export default WebInfo
