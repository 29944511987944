import moment from 'moment'
import { numberWithCommas } from '../utils'
import chPlayIcon from '../asset/icons/ch_play.svg'
import appleIcon from '../asset/icons/apple.svg'
import { TRANSACTION_STATUS } from '../types/enum'
import axiosClient from '../apis/axiosClient'
import { TRANSACTION } from '../apis/urlConfig'

export const COLUMN_TABLE_USER = [
  {
    title: '회원아이디',
    dataIndex: 'email',
    render: (text: string) => <span>{text}</span>,
    sort: false,
  },
  {
    title: '전화 번호',
    dataIndex: 'mobileNumber',
    render: (text?: string) => <span>{text}</span>,
    sort: false,
  },
  {
    title: '가입일',
    dataIndex: 'createdAt',
    render: (text: string) => <span>{moment(text).format('YYYY/MM/DD')}</span>,
    sort: false,
  },
  {
    title: '구입횟수',
    dataIndex: 'totalPayment',
    render: (text: number) => <span>{text}</span>,
    sort: false,
  },
  {
    title: 'id',
    dataIndex: 'id',
    sort: false,
    render: (text: string) => <span>{text}</span>,
  },
]
export const COLUMN_TABLE_UPLOAD_VIDEO = [
  {
    title: '회원아이디',
    dataIndex: '회원아이디',
    render: (text: string) => <span>{text}</span>,
    sort: false,
  },
  {
    title: '전화 번호',
    dataIndex: '전화번호',
    render: (text: string) => <span>{text}</span>,
    sort: false,
  },
  {
    title: '생년월일',
    dataIndex: '생년월일',
    render: (text: string) => <span>{moment(text).format('YYYY/MM/DD')}</span>,
    sort: false,
  },
  {
    title: '성별',
    dataIndex: '성별',
    render: (text: string) => <span>{text}</span>,
    sort: false,
  },
]

export const COLUMN_TABLE_PAYMENT = [
  {
    title: 'id',
    dataIndex: 'id',
    sort: false,
    render: (text: string) => <span>{text}</span>,
  },
  {
    title: '아이디',
    dataIndex: 'adminEmail',
    render: (text: string) => <span>{text}</span>,
    sort: false,
  },
  {
    title: '결제 테마',
    dataIndex: 'theme',
    render: (theme: any) => <span>{theme.name}</span>,
    sort: false,
  },
  {
    title: '결제상품',
    dataIndex: 'webInfo',
    render: (item?: any) => {
      console.log('item render', item);
      return (
        <span>
          <p style={{margin: 0, padding: 0}}>{item?.name || '사이트 이름 미정'}</p>
          <p style={{margin: 0, padding: 0}}>{item?.webInfo?.domainName || '도메인 미정'}</p>
        </span>)
    },
    sort: false,
  },
  {
    title: '결제일',
    dataIndex: 'createdAt',
    render: (text: string) => <span>{moment(text).format('YYYY//MM/DD')}</span>,
    sort: false,
  },
  {
    title: '결제 금액',
    dataIndex: 'price',
    render: (text: number) => <span>{numberWithCommas(text)}원</span>,
    sort: false,
  },
  {
    title: '처리상태',
    dataIndex: 'status',
    render: (status: TRANSACTION_STATUS) => <span></span>,
    sort: false,
  },
]

export const COLUMN_TABLE_PROJECT = [
  {
    title: 'id',
    dataIndex: 'id',
    sort: false,
    render: (text: string) => <span>{text}</span>,
  },
  {
    title: '만료일',
    dataIndex: 'remainingDays',
    sort: false,
    render: (text: number) => {
      const remainingDays = Math.floor(text)
      if (remainingDays === 0) {
        return <span>{remainingDays} 만료</span>
      }
      else {
        return <span>{remainingDays}일 남음</span>
      }
    },
  },
  {
    title: '결제 테마',
    dataIndex: 'theme',
    render: (theme: any) => <span>{theme.name}</span>,
    sort: false,
  },

  {
    title: '개설일',
    dataIndex: 'createdAt',
    render: (text: string) => <span>{moment(text).format('YYYY/MM/DD')}</span>,
    sort: true,
  },
  {
    title: '아이디',
    dataIndex: 'adminEmail',
    render: (text: string) => <span>{text}</span>,
    sort: false,
  },
  {
    title: '수정 요청',
    dataIndex: 'site',
    render: (site: any) =>
      site && (
        <div style={{ display: 'flex', gap: '4px', alignItems: 'center', justifyContent: 'center' }}>
          {(site.iosInfo && site.typeApp === 'IOS') ? (
            <img src={appleIcon} alt='' />
          ) : (site.androidInfo && site.typeApp === 'ANDROID') ? (
            <img src={chPlayIcon} alt='' />
          ) : (
            ''
          )}
          {/* {site.name} */}
          {(site.iosInfo && site.typeApp === 'IOS') ? (
            '앱스토어 정보'
          ) : (site.androidInfo && site.typeApp === 'ANDROID') ? (
            '구글플레이 정보'
          ) : ('')
          }
        </div>
      ),
    sort: false,
  },
]

export const COLUMN_TABLE_TRASACTION = [
  {
    title: 'id',
    dataIndex: 'id',
    sort: false,
    render: (text: string) => <span>{text}</span>,
  },
  {
    title: '아이디',
    dataIndex: 'payer',
    render: (payer: any) => <span>{payer.email}</span>,
    sort: false,
  },

  {
    title: '지불 날짜',
    dataIndex: 'createdAt',
    render: (text: string) => <span>{moment(text).format('YYYY/MM/DD')}</span>,
    sort: false,
  },
  {
    title: '상태',
    dataIndex: 'value',
    render: (text: number) => <span>{numberWithCommas(text)} KRW</span>,
    sort: false,
  },
  {
    title: 'Status',
    dataIndex: 'status',
    render: (status: TRANSACTION_STATUS) => <span></span>,
    sort: false,
  },
  {
    title: '은행 계좌 번호',
    dataIndex: 'accountNumber',
    render: (text: number) => <span>{text}</span>,
    sort: false,
  },
]
