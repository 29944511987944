import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { ConversationType } from "../../types/conversation.type";
import { RootState } from "../../app/store";
import { mereListById } from "../../utils";
import { TYPE_SORT } from "../../types/enum";

// const initialState: {listData: ConversationType[]; page?: number} = {
//   listData: [],
// }

const initialState: {
  listData: ConversationType[];
  page?: number;
  totalData?: number;
  _sort?: TYPE_SORT;
} = {
  listData: [],
};

const conversationSlice = createSlice({
  name: "conversation",
  initialState,
  // reducers: {
  //   getList: (state, action: PayloadAction<{params?: any}>) => {
  //     state.page = Number(action.payload.params.page)
  //   },
  //   getListSuccess: (
  //     state,
  //     action: PayloadAction<{listData: ConversationType[]; page: number}>
  //   ) => {
  //     if (action.payload.page) {
  //       state.listData = mereListById(
  //         state.listData,
  //         action.payload.listData
  //       ) as any
  //     }
  //   },
  // },

  reducers: {
    getList: (state, action: PayloadAction<{ params?: any }>) => {
      state.page = Number(action.payload.params.page);
      state._sort = action.payload.params._sort;
    },
    getListSuccess: (
      state,
      action: PayloadAction<{
        listData: ConversationType[];
        page: number;
        totalData: number;
      }>
    ) => {
      if (action.payload.page) {
        state.listData = mereListById(
          state.listData,
          action.payload.listData
        ) as any;
      }
      else {
        state.listData = action.payload.listData
      }
      state.totalData = action.payload.totalData;
    },
    createSite: (
      state,
      action: PayloadAction<{
        newData: ConversationType;
      }>
    ) => {
      const { newData } = action.payload;
      state.listData = [newData, ...state.listData];
    },
  },
});

export const conversationActions = conversationSlice.actions;
export const conversationReducer = conversationSlice.reducer;

export const selectListData = (state: RootState) =>
  state.conversationReducer.listData;

export const selectTotalData = (state: RootState) =>
  state.conversationReducer.totalData;
