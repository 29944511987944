import {Button, makeStyles} from '@material-ui/core'
import React from 'react'
import InputBase from '../../components/input'
import AddIcon from '@material-ui/icons/Add'

const useStyles = makeStyles({
  youtube_link_container: {
    '&>div': {
      width: '704px',
      padding: '24px',
      border: '1px solid #D0D5DD',
      borderRadius: '12px',
      marginTop: '1rem',
    },
  },
})

const YoutubeLink = () => {
  const classes = useStyles()
  return (
    <div className={classes.youtube_link_container}>
      <div>
        <InputBase
          label='유튜브 URL'
          placeholder='URL입력해주세요.'
          onChange={() => {}}
        />
        <InputBase
          label='설명'
          placeholder='입력해주세요'
          onChange={() => {}}
        />
        <Button
          variant='contained'
          color='primary'
          style={{borderRadius: 'none', marginTop: '1rem'}}
        >
          <AddIcon />
          동영상 생성
        </Button>
      </div>
    </div>
  )
}

export default YoutubeLink
