import {PayloadAction} from '@reduxjs/toolkit'
import {NavigateFunction} from 'react-router-dom'
import {call, put, takeEvery} from 'redux-saga/effects'
import {authApi} from '../../apis/authApi'
import {LoginType} from '../../types/login.type'
import {authAction} from './authSlice'
import {snackBarActions} from '../../components/snackbar/snackbarSlice'
import {ROUTE} from '../../router/routes'
import {setTokens} from '../../utils'

function* login(
  action: PayloadAction<{
    data: {username: string; password: string}
    history: NavigateFunction
  }>
) {
  try {
    const data: {data: LoginType} = yield call(
      authApi.login,
      action.payload.data
    )
    setTokens(data.data)
    yield put(
      snackBarActions.setStateSnackBar({
        content: '잘못된 비밀번호 또는 계정',
        type: 'success',
      })
    )
    action.payload.history(ROUTE.USER)
  } catch (error) {
    yield put(
      snackBarActions.setStateSnackBar({
        content: '잘못된 비밀번호 또는 계정',
        type: 'error',
      })
    )
  }
}

export default function* authSaga() {
  yield takeEvery(authAction.login.type, login)
}
