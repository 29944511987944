import React from 'react'

const InfoCircle = (props: { color?: string }) => {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M9.16699 7.49984H10.8337V5.83317H9.16699M10.0003 16.6665C6.32533 16.6665 3.33366 13.6748 3.33366 9.99984C3.33366 6.32484 6.32533 3.33317 10.0003 3.33317C13.6753 3.33317 16.667 6.32484 16.667 9.99984C16.667 13.6748 13.6753 16.6665 10.0003 16.6665ZM10.0003 1.6665C8.90598 1.6665 7.82234 1.88205 6.8113 2.30084C5.80025 2.71963 4.88159 3.33346 4.10777 4.10728C2.54497 5.67008 1.66699 7.7897 1.66699 9.99984C1.66699 12.21 2.54497 14.3296 4.10777 15.8924C4.88159 16.6662 5.80025 17.28 6.8113 17.6988C7.82234 18.1176 8.90598 18.3332 10.0003 18.3332C12.2105 18.3332 14.3301 17.4552 15.8929 15.8924C17.4557 14.3296 18.3337 12.21 18.3337 9.99984C18.3337 8.90549 18.1181 7.82185 17.6993 6.81081C17.2805 5.79976 16.6667 4.8811 15.8929 4.10728C15.1191 3.33346 14.2004 2.71963 13.1894 2.30084C12.1783 1.88205 11.0947 1.6665 10.0003 1.6665ZM9.16699 14.1665H10.8337V9.1665H9.16699V14.1665Z"
        fill={props.color ? props.color : '#343941'} />
    </svg>
  )
}

export default InfoCircle
