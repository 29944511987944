import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { RootState } from "../../app/store";
import { GlobalType } from "../../types/global.type";

const initialState: {
  listData: GlobalType;
} = {
  listData: {
    site: 0,
    conversation: 0,
    transaction: 0,
  },
};

const globalSlice = createSlice({
  name: "global",
  initialState,
  reducers: {
    getList: (state, action: PayloadAction<{ params?: any }>) => {},
    getListSuccess: (
      state,
      action: PayloadAction<{
        listData: GlobalType;
      }>
    ) => {
      state.listData = action.payload.listData;
    },
  },
});

export const globalActions = globalSlice.actions;
export const globalReducer = globalSlice.reducer;

export const selectListData = (state: RootState) =>
  state.globalReducer.listData;
