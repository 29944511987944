import React, {useEffect, useRef, useState} from 'react'
import {makeStyles} from '@material-ui/styles'
import Button from '@material-ui/core/Button'
import CloseIcon from '@material-ui/icons/Close'

const useStyle = makeStyles({
  upload_container: {
    '&>div': {
      display: 'flex',
      marginBottom: '1rem',
      '&>img': {
        objectFit: 'cover',
      },
    },
  },
})

const Upload = (props: {style?: React.CSSProperties; textButton?: string, img?: string}) => {
  const classes = useStyle()
  const refInput = useRef<HTMLInputElement>(null)
  const [image, setImage] = useState(
    // 'https://znews-photo.zingcdn.me/w660/Uploaded/qhj_yvobvhfwbv/2018_07_18/Nguyen_Huy_Binh1.jpg'
    ''
  )
  const handleChange = (e: any) => {
    const img = URL.createObjectURL(e.target.files[0])
    setImage(img)
  }

  useEffect(() => {
   if(props.img){
      setImage(props.img)
   }
  }, [props.img])

  return (
    <div className={classes.upload_container}>
      <input
        ref={refInput}
        type='file'
        hidden
        onChange={(e) => {
          handleChange(e)
        }}
      />
      {image && (
        <div>
          <img src={image} alt='' style={props.style} />
          <span onClick={() => setImage('')}>
            <CloseIcon />
          </span>
        </div>
      )}

      <Button
        variant='outlined'
        style={{borderRadius: 0}}
        onClick={() => {
          refInput.current?.click()
        }}
      >
        {props.textButton ? props.textButton : '업로드'}
      </Button>
    </div>
  )
}

export default Upload
