import { useEffect, useLayoutEffect, useState, useRef } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import axiosClient from '../../apis/axiosClient'
import { GLOBAL } from '../../apis/urlConfig'
import CardIcon from '../../asset/icons/card_icon'
import CardIcon2 from '../../asset/icons/card_icon_2'
import LinkRoundAngle from '../../asset/icons/link_round_angle'
import MoneyTimeIcon from '../../asset/icons/money_time_icon'
import QuestionCircle from '../../asset/icons/question_circle'
import UsersGroupRounded from '../../asset/icons/users_group_rounded'
import iconLogoutSidebar from '../../asset/images/iconLogoutSidebar.png'
import { ROUTE } from '../../router/routes'
import { globalActions, selectListData } from '../../feature/global/globalSlice'
import { useAppDispatch, useAppSelector } from '../../app/hooks'
import { GlobalType } from '../../types/global.type'
import { io } from "socket.io-client"
import { conversationActions } from '../../feature/conversation/conversationSlice'
import { setTokens } from '../../apis/axiosClient'
import { transactionActions } from '../../feature/transaction/transactionSlice'


const dataDeposits = [
  {
    name: '회원목록',
    path: ROUTE.USER,
  },
  {
    name: '접수목록',
    path: ROUTE.PAYMENT_MANAGEMENT,
  },
  {
    name: '결제내역',
    path: ROUTE.TRANSACTION,
  },
  {
    name: '수정요청 목록',
    path: ROUTE.PROJECT_MANAGEMENT,
  },
  {
    name: '고객센터',
    path: ROUTE.CUSTOMER_SERVICE_CENTER,
  },
  {
    name: 'Youtube link',
    path: ROUTE.YOUTUBE_LINK,
  },
]

export default function SideBar(props: { collapse?: boolean }) {
  const location = useLocation()
  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  const dataGlobal = useAppSelector(selectListData)
  const socketRef = useRef<any>(null)
  const [idSocket, setIdSocket] = useState('')
  const [isToken, setIsToken] = useState(false)

  console.log('dataGlobal', dataGlobal);


  const handleLogOut = () => {
    if (window.confirm('로그아웃하시겠습니까?')) {
      localStorage.clear()
      navigate(ROUTE.LOGIN)
    }
  }
  const renderIcon = (path: string) => {
    switch (path) {
      case ROUTE.USER:
        return (
          <UsersGroupRounded
            color={location.pathname === ROUTE.USER ? 'black' : ''}
          />
        )

      case ROUTE.PAYMENT_MANAGEMENT:
        return (
          <CardIcon
            color={
              location.pathname === ROUTE.PAYMENT_MANAGEMENT ? 'black' : ''
            }
          />
        )

      case ROUTE.TRANSACTION:
        return (
          <MoneyTimeIcon
            color={
              location.pathname === ROUTE.TRANSACTION ? 'black' : ''
            }
          />
        )

      case ROUTE.PROJECT_MANAGEMENT:
        return (
          <CardIcon2
            color={
              // (location.pathname === ROUTE.PROJECT_MANAGEMENT) ? 'black' : ''
              (location.pathname.includes(ROUTE.PROJECT_MANAGEMENT)) ? 'black' : ''
            }
          />
        )

      case ROUTE.CUSTOMER_SERVICE_CENTER:
        return (
          <QuestionCircle
            color={
              location.pathname === ROUTE.CUSTOMER_SERVICE_CENTER ? 'black' : ''
            }
          />
        )
      case ROUTE.YOUTUBE_LINK:
        return (
          <LinkRoundAngle
            color={location.pathname === ROUTE.YOUTUBE_LINK ? 'black' : ''}
          />
        )

      default:
        break
    }
  }

  const renderNoti = (path: string) => {
    switch (path) {
      case ROUTE.USER:
        break;

      case ROUTE.PAYMENT_MANAGEMENT:
        return (
          // <div
          //   style={dataGlobal.site ? {
          //     display: 'flex',
          //     width: '24px',
          //     height: '24px',
          //     backgroundColor: '#2B83FE',
          //     borderRadius: '50%',
          //     justifyContent: 'center',
          //     alignItems: 'center',
          //     color: '#fff',
          //     fontSize: '14px',
          //   } :
          //     {}} >
          //   {dataGlobal.site ? dataGlobal.site : ''}
          // </div>
          <div></div>
        )

      case ROUTE.TRANSACTION:
        return (
          <div
            style={dataGlobal.transaction ? {
              display: 'flex',
              width: '24px',
              height: '24px',
              backgroundColor: '#2B83FE',
              borderRadius: '50%',
              justifyContent: 'center',
              alignItems: 'center',
              color: '#fff',
              fontSize: '14px',
            } : {}} >
            {dataGlobal.transaction ? dataGlobal.transaction : ''}
          </div>
        )

      case ROUTE.PROJECT_MANAGEMENT:
        return (
          <div
            style={dataGlobal.site ? {
              display: 'flex',
              width: '24px',
              height: '24px',
              backgroundColor: '#2B83FE',
              borderRadius: '50%',
              justifyContent: 'center',
              alignItems: 'center',
              color: '#fff',
              fontSize: '14px',
            } :
              {}}>
            {dataGlobal.site ? dataGlobal.site : ''}
          </div>
        )


      case ROUTE.CUSTOMER_SERVICE_CENTER:
        return (
          <div
            style={dataGlobal.conversation ? {
              display: 'flex',
              width: '24px',
              height: '24px',
              backgroundColor: '#2B83FE',
              borderRadius: '50%',
              justifyContent: 'center',
              alignItems: 'center',
              color: '#fff',
              fontSize: '14px',
            } :
              {}}>
            {dataGlobal.conversation ? dataGlobal.conversation : ''}
          </div>
        )
      case ROUTE.YOUTUBE_LINK:
        break;

      default:
        break
    }
  }

  useEffect(() => {
    setTokens()
    setIsToken(true)
  }, [])
  useEffect(() => {
    if (isToken) {
      dispatch(globalActions.getList({ params: undefined }))
    }
  }, [location.pathname, isToken])

  useEffect(() => {
    socketRef.current = io('https://server.gmapps.net', {
      extraHeaders: {
        Authorization: "Bearer " + localStorage.getItem('accessToken'),
      }
    })

    socketRef.current.on('getId', (data: string) => {
      setIdSocket(data)
    })

    socketRef.current.on('createConversation', (dataGot: any) => {
      console.log('dataGot', dataGot);

      dispatch(globalActions.getList({ params: undefined }))
      dispatch(conversationActions.createSite({ newData: dataGot }))
    })

    socketRef.current.on('userDeposit', (dataGot: any) => {
      console.log('dataGotUserDeposit', dataGot);
      dispatch(globalActions.getList({ params: undefined }))
    })
    socketRef.current.on('userUpdateSite', (dataGot: any) => {
      console.log('dataGotUserUpdateSite', dataGot);
      dispatch(globalActions.getList({ params: undefined }))
    })

    return () => {
      socketRef.current.disconnect();
    };

  }, []);

  return (
    <div
      style={{
        minHeight: 'calc(100vh - 76px)',
        backgroundColor: '#fff',
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        boxSizing: 'border-box',
        boxShadow: '1px 0px 4px rgba(0, 0, 0, 0.12)',
      }}
    >
      <div
        style={{
          margin: '24px 24px 0 24px',
          flex: 1,
          overflow: 'auto',
        }}
      >
        {dataDeposits.map((item, index) => (
          <div
            style={
              (location.pathname === item.path || (
                location.pathname.includes(item.path) && item.path !== '/'
              ))
                ? {
                  display: 'flex',
                  // alignItems: 'center',
                  padding: '6px ',
                  width: props.collapse ? '60px' : '208px',
                  gap: '8px',
                  fontWeight: 500,
                  color: '#000',
                  backgroundColor: '#EDEDED',
                  borderRadius: '8px',

                  margin: '0.5rem 0',
                  cursor: 'pointer',
                  justifyContent: props.collapse ? 'center' : 'start',
                }
                : {
                  display: 'flex',
                  alignItems: 'center',
                  padding: '6px',
                  gap: '8px',
                  fontWeight: 500,
                  color: '#70777F',
                  margin: '0.5rem 0',
                  cursor: 'pointer',
                  width: props.collapse ? '60px' : '208px',
                  justifyItems: 'center',
                  justifyContent: props.collapse ? 'center' : 'start',
                }
            }
            onClick={() => navigate(item.path)}
          >
            {renderIcon(item.path)}
            {!props.collapse && (
              <p
                style={{
                  padding: 0,
                  margin: 0,
                  fontSize: '16px',
                  fontWeight: 500,
                }}
              >
                {item.name}
              </p>
            )}
            {renderNoti(item.path)}
          </div>
        ))}
      </div>
      <div style={{ borderTop: '1px solid #D0D5DD' }}>
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            padding: '24px',
            gap: '8px',
            justifyContent: !props.collapse ? 'start' : 'center',
          }}
        >
          <img
            src={iconLogoutSidebar}
            style={{ height: '24px', width: '24px' }}
            alt=''
            onClick={handleLogOut}
          />
          {!props.collapse && (
            <p
              style={{ padding: 0, margin: 0, fontSize: '16px', fontWeight: 500 }}
              onClick={handleLogOut}
            >
              고객센터
            </p>
          )}
        </div>
      </div>
    </div>
  )
}
