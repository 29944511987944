import { PayloadAction } from "@reduxjs/toolkit";
import { call, put, takeEvery } from "redux-saga/effects";
import { conversationApi } from "../../apis/conversationApi";
import { ConversationType } from "../../types/conversation.type";
import { loadingActions } from "../../components/loading/loadingSlice";
import { globalApi } from "../../apis/globalApi";
import { globalActions } from "./globalSlice";
import { GlobalType } from "../../types/global.type";
import { conversationActions } from "../conversation/conversationSlice";


function* getList(action: PayloadAction<{ params?: any }>) {
  try {
    const res: { data: GlobalType} = yield call(
      globalApi.getList,
      action.payload.params
    );
    yield put(
      globalActions.getListSuccess({
        listData: res.data,
      })
    );
  } catch (error) {
    console.log(error);
  }
}

export default function* globalSaga() {
  yield takeEvery(globalActions.getList.type, getList);
}
