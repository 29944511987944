import { makeStyles } from '@material-ui/core'
import { InputAdornment } from '@material-ui/core';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import { useEffect, useLayoutEffect, useState } from 'react'
import { SITE, TRANSACTION } from '../../apis/urlConfig'
import SelectCustom from '../../components/select_custom'
import TableCustom from '../../components/table'
import { COLUMN_TABLE_PROJECT } from '../../constants/column'
import searchIcon from '../../asset/images/searchIcon.png'

import {
  IS_ACTIVE,
  PAYMENT_METHOD,
  TYPE_TRANSACTION,
} from '../../types/enum'
import { useAppSelector } from '../../app/hooks';
import { selectListData } from '../../feature/global/globalSlice';
import { GlobalType } from '../../types/global.type';

const useStyles = makeStyles({
  project_management_container: {
    '&>div:nth-of-type(1)': {
      margin: '2rem 0 1rem 0',
      display: 'flex',
      justifyContent: 'space-between',
      '&>div': {
        display: 'flex',
      },
    },
    '&>p': {
      display: 'flex',
      fontSize: '18px',
      fontWeight: 500,
      '&>span': {
        width: '28px',
        height: '28px',
        display: 'flex',
        background: '#2B83FE',
        borderRadius: '100px',
        justifyContent: 'center',
        fontSize: '14px',
        fontWeight: 500,
        color: 'white',
        alignItems: 'center',
        marginLeft: '8px',
      },
    },
  },
  search: {
    width: '300px',
    borderRadius: '8px',
    marginRight: '16px',
  },
})

const ProjectManagement = () => {
  const classes = useStyles()
  const dataGlobal = useAppSelector(selectListData)
  const [paramsHeader, setParamsHeader] = useState<{
    _sort: string
    typeTransaction?: TYPE_TRANSACTION
    isActive?: IS_ACTIVE
    paymentMethod?: PAYMENT_METHOD
    search?: string
  }>({
    _sort: 'createdAt@desc',
    isActive: IS_ACTIVE.COMPLETE,
  })
  console.log('params', paramsHeader)

  const [reload, setReload] = useState<boolean>(false)
  const [valueSearch, setValueSearch] = useState<string>('')

  const handleSearch = (event: any) => {
    if (event.key === 'Enter') {
      setParamsHeader({ ...paramsHeader, search: valueSearch })
    }
  }

  useEffect(() => {
    console.log('dataGlobal Site', dataGlobal.site);
    setReload(!reload)
  }, [dataGlobal.site])

  return (
    <div className={classes.project_management_container}>
      <div>
        <div>
          <OutlinedInput
            id="outlined-adornment-weight"
            className={classes.search}
            value={valueSearch}
            onChange={(e) => setValueSearch(e.target.value)}
            // onKeyPress={handleSearch}
            startAdornment={
              <InputAdornment
                style={{ margin: 0, padding: 0, }}
                position='start'
              >
                <img
                  src={searchIcon}
                  style={{ height: '20px', width: '20px' }}
                />
              </InputAdornment>
            }
            labelWidth={0}
            placeholder='Search bar...'
            inputProps={{
              style: {
                padding: '0 0 0 8px',
                margin: 0,
                fontSize: '16px',
                fontWeight: 400,
                height: '38px',
              },
            }}
          />
          <SelectCustom
            data={[
              { label: '처리된 목록', value: 'true' },
              { label: '미처리 목록', value: 'false' },
            ]}
            value={paramsHeader.isActive}
            onChange={(e: any) => setParamsHeader({ ...paramsHeader, isActive: e })}
          />
        </div>
      </div>
      <p>
        수정요청 목록
        {dataGlobal.site > 0 && <span>{dataGlobal.site}</span>}
      </p>
      <TableCustom
        column={COLUMN_TABLE_PROJECT}
        select
        // action
        hideUpdate
        hideSee
        hideDelete
        url={SITE}
        params={paramsHeader}
        isReload={reload}
      />
    </div>
  )
}

export default ProjectManagement
