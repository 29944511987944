import { useEffect, useState } from 'react'
import { makeStyles } from '@material-ui/core'
import KeyboardBackspaceIcon from '@material-ui/icons/KeyboardBackspace'
import infoCircle from '../../asset/icons/info_circle.svg'
import InputCustom from '../../components/input_custom'
import questionCircle from '../../asset/icons/question_circle.svg'
import Upload from '../../components/upload'
import Button from '@material-ui/core/Button'
import Radio from '@material-ui/core/Radio'
import RadioGroup from '@material-ui/core/RadioGroup'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import FormControl from '@material-ui/core/FormControl'
import FormLabel from '@material-ui/core/FormLabel'
import ColorPicker from '../../components/color_picker'
import { useNavigate, useParams } from 'react-router-dom'
import { useAppDispatch } from '../../app/hooks'
import { SiteType } from '../../types/site.type'
import { loadingActions } from '../../components/loading/loadingSlice'
import { STATUS_SITE_UPDATE } from '../../types/enum'
import { ROUTE } from '../../router/routes'
import axiosClient from '../../apis/axiosClient'
import { SITE } from '../../apis/urlConfig'
import { snackBarActions } from '../../components/snackbar/snackbarSlice'
import { InputColor } from '../../components/base/InputColor'
import { globalActions } from '../../feature/global/globalSlice'

const useStyle = makeStyles({
  payment_apple_container: {
    paddingBottom: '1rem',
    '&>p:nth-of-type(1)': {
      display: 'flex',
      fontSize: '18px',
      '&>span': {
        marginRight: '8px',
      },
    },
    '&>div:nth-of-type(1)': {
      padding: '1rem',
      background: 'rgba(235, 243, 255, 0.24)',
      border: '1px solid #EDEDED',
      borderRadius: '2px',

      '&>p:nth-of-type(1)': {
        display: 'flex',
        alignItems: 'center',
        '&>img': {
          marginRight: '8px',
        },
      },
      '&>p:nth-of-type(2)': {
        fontWeight: 400,
        fontSize: '14px',
        margin: 0,
      },
      '&>p:nth-of-type(3)': {
        fontWeight: 400,
        fontSize: '14px',
        margin: 0,
        '&>span': {
          color: '#2B83FE',
        },
      },
      '&>ul': {
        margin: 0,
        '&>li': {
          fontWeight: 400,
          fontSize: '14px',
        },
      },
    },
    '&>div:nth-of-type(2)': {
      padding: '24px 32px 24px 24px',
      background: '#FAFAFA',
      borderLeft: '3px solid #2B83FE',
      marginTop: '1rem',
      '&>p': {
        margin: 0,
        fontWeight: 400,
        fontSize: '14px',
      },
    },
    '&>div:nth-of-type(3)': {
      marginTop: '1rem',
      padding: '24px',
      border: '1px solid #D0D5DD',
      borderRadius: '12px',
    },

    '&>div:nth-of-type(4)': {
      marginTop: '1rem',
      padding: '24px',
      border: '1px solid #D0D5DD',
      borderRadius: '12px',
      '&>div:nth-of-type(1)': {
        display: 'flex',
        '&>div': {
          width: '50%',
          '&>p:nth-of-type(1)': {
            fontWeight: 400,
            margin: 0,
            display: 'flex',
            alignItems: 'center',
            '&>img': {
              marginLeft: '5px',
            },
          },
          '&>p:nth-of-type(2)': {
            fontWeight: 400,
            fontSize: '14px',
            color: '#70777F',
            marginTop: '5px',
          },
        },
      },
    },
    '&>div:nth-of-type(5)': {
      marginTop: '1rem',
      padding: '24px',
      border: '1px solid #D0D5DD',
      borderRadius: '12px',
    },
    '&>div:nth-of-type(6)': {
      marginTop: '1rem',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      gap: '32px',
    }
  },
  title_div: {
    marginTop: 0,
  },
})

const PaymentApple = () => {
  const classes = useStyle()
  const { id } = useParams()
  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  const [site, setSite] = useState<SiteType>()
  const [selectedValue, setSelectedValue] = useState('WHITE')
  const [color, setColor] = useState('#000')

  console.log('Site Detail', site);
  console.log('id', id);

  const handleColorChange = (color: string) => {
    setColor(color);
  }

  const handleUpdateSite = async () => {
    try {
      dispatch(loadingActions.openLoading())
      const dataPatch: { key: string } = {
        key: 'iosInfo'
      }
      if (site?.iosInfo?.status === STATUS_SITE_UPDATE.CREATED) {
        console.log('Site update succeed')
        dispatch(loadingActions.loadingSuccess())
        navigate(ROUTE.PROJECT_MANAGEMENT)
      }
      else {
        axiosClient.patch(`${SITE}/update/${id}/active-info`, dataPatch)
          .then((res) => {
            console.log('Site update succeed:', res)
            dispatch(loadingActions.loadingSuccess())

            dispatch(snackBarActions.setStateSnackBar({
              content: '성공',
              type: 'success',
            }))
            dispatch(globalActions.getList({ params: undefined }))
            navigate(ROUTE.PROJECT_MANAGEMENT)
          })
          .catch((error: any) => {
            console.log(error)
            dispatch(loadingActions.loadingSuccess())
            dispatch(snackBarActions.setStateSnackBar({
              content: '실패',
              type: 'error',
            }))
          })
      }

    } catch (error) {
      console.log('error:', error)
      dispatch(loadingActions.loadingSuccess())
      dispatch(snackBarActions.setStateSnackBar({
        content: '실패',
        type: 'error',
      }))
    }
  }

  useEffect(() => {
    const getDetailConversation = async () => {
      try {
        dispatch(loadingActions.openLoading())
        const data: { data: SiteType } = await axiosClient.get(
          `${SITE}/get/${id}`
        )
        setSite(data.data)
        setSelectedValue(data.data?.iosInfo?.textColor || 'WHITE')
        setColor(data.data?.iosInfo?.backgroundColor || "#000")
        dispatch(loadingActions.loadingSuccess())
      } catch (error) {
        console.log(error)
        dispatch(loadingActions.loadingSuccess())
      }
    }
    getDetailConversation()
  }, [id])
  return (
    <div className={classes.payment_apple_container}>
      <p>
        <span onClick={() => navigate(-1)}>
          <KeyboardBackspaceIcon />
        </span>
        앱스토어 등록 및 수정
      </p>

      <div>
        <p>
          <img src={infoCircle} alt='' /> 주의
        </p>
        <p>
          iOS용 앱은 Apple App Store에 등록하기 위해 Apple의 심사를 받아야
          합니다. Apple의 정책에 의해 거절될 수 있으며 자주 발생하는 유형은
          다음과 같습니다.
        </p>
        <ul>
          <li>
            타사 플랫폼 언급 : 내 사이트에 Apple 플랫폼 외의 Android, Google
            Play 등과 관련된 문구나 이미지가 포함되면 심사에 거절될 수 있습니다.
            안드로이드가 탑재된 기기(예: 갤럭시S7)의 이미지 사용도 거절 사유가
            될 수 있습니다.
          </li>
          <li>
            앱 내 구매 : 디지털 상품, 이용권 등 비실물 상품을 앱 내에서 판매할
            경우 등록 거절 사유가 됩니다. (실물 상품을 판매하는 쇼핑몰은
            문제되지 않습니다.)
          </li>
          <li>
            사행성 콘텐츠 : 도박 등 사행성 콘텐츠가 포함된 사이트는 등록 거절
            사유가 됩니다.
          </li>
          <li>
            저작권/상표권 침해 : 허가받지 않은 타사의 상표를 콘텐츠에
            포함시키거나 저작권을 위반한 콘텐츠가 포함될 경우 등록 거절 사유가
            됩니다.
          </li>
          <li>
            개인정보수집 : 과도한 개인정보를 수집하는 사이트는 등록 거절 사유가
            됩니다.
          </li>
          <li>
            부정확한 설명 : 제출한 스크린샷이나 설명이 앱과 관계가 없거나
            충분하지 않은 경우 거절 사유가 됩니다.
          </li>
          <li>
            컨테이너 앱 : 사이트 구성이 단순 회사소개거나 일반 앱 사용자가
            활동할 수 있는 컨텐츠가 부족할 경우 등록이 제한 됩니다.
          </li>
        </ul>
        <p>
          보다 자세한 사항은 <span>Apple App Store 앱 심사 지침</span>을
          참고하시기 바랍니다.
        </p>
      </div>
      <div>
        <p>
          Apple App Store 심사는 신청일로부터 약 10영업일이 소요될 수 있습니다.
        </p>
        <p>
          파트너도 앞으로 앱 신청/업데이트시 개별 Apple 개발자 계정이
          필요합니다.
        </p>
      </div>

      <div>
        <p className={classes.title_div}>개발자 정보</p>
        <InputCustom
          label='Apple 개발자 계정'
          onChange={() => { }}
          placeholder='입력해주세요'
          style={{ width: '522px' }}
          iconLabel={questionCircle}
          value={site?.iosInfo?.user}
          disabled
        />

        <InputCustom
          label='Apple 개발자 계정 비밀번호'
          onChange={() => { }}
          placeholder='입력해주세요'
          style={{ width: '522px' }}
          iconLabel={questionCircle}
          value={site?.iosInfo?.password}
          disabled
        />

        <InputCustom
          label='클라이언트 담당자 연락처'
          onChange={() => { }}
          placeholder='입력해주세요'
          style={{ width: '522px' }}
          iconLabel={questionCircle}
          value={site?.iosInfo?.contactInfo}
          disabled
        />

        <InputCustom
          label='앱 제목'
          onChange={() => { }}
          placeholder='입력해주세요'
          style={{ width: '522px' }}
          iconLabel={questionCircle}
          value={site?.iosInfo?.appName}
          disabled
        />

        <InputCustom
          label='앱 설명'
          onChange={() => { }}
          placeholder='입력해주세요'
          style={{ width: '522px', height: '105px' }}
          iconLabel={questionCircle}
          textArea
          value={site?.iosInfo?.description}
          disabled
        />

        <InputCustom
          label='검색키워드'
          onChange={() => { }}
          placeholder='예시)맛집,강남맛집,논현맛집,강남샐러드'
          style={{ width: '522px' }}
          iconLabel={questionCircle}
          value={site?.iosInfo?.keyword}
          disabled
        />
      </div>

      <div>
        <p className={classes.title_div}>그래픽 저작물</p>
        <div>
          <div>
            <p>
              고해상도 아이콘 가로세로 1024x1024{' '}
              <img src={questionCircle} alt='' />
            </p>
            <p>32비트 PNG(알파 없음), 모서리는 자동으로 둥글게 처리됩니다.</p>
            <Upload style={{ width: '358px', height: '358px' }} img={site?.iosInfo?.icon} />
          </div>
          <div>
            <p>
              시작화면 (선택사항) <img src={questionCircle} alt='' />
            </p>
            <p>가로x세로 1440x2960 JPG또는 24비트 PNG(알파 없음)</p>
            <Upload style={{ width: '175px', height: '358px' }} img={site?.iosInfo?.homeScreen} />
          </div>
        </div>
      </div>
      <div>
        <FormControl component='fieldset'>
          <FormLabel component='legend' style={{ color: 'black' }}>
            상단 상태바 글자색상
          </FormLabel>
          <RadioGroup
            row
            aria-label='position'
            name='position'
            defaultValue='WHITE'
            value={selectedValue}
          >
            <FormControlLabel
              value='WHITE'
              control={<Radio color='primary' />}
              label='흰색'
              labelPlacement='end'
            />
            <FormControlLabel
              value='BLACK'
              control={<Radio color='primary' />}
              label='검정색'
              labelPlacement='end'
            />
          </RadioGroup>
        </FormControl>
        <div>
          <p>상단 상태바 배경색</p>
          <InputColor
            style={{ border: 'none' }}
            onChange={handleColorChange}
            value={color}
          />
        </div>
      </div>
      <div>
        <Button
          variant='outlined'
          style={{ borderRadius: 0, fontSize: '16px', textTransform: 'none' }}
          onClick={() => navigate(ROUTE.PROJECT_MANAGEMENT)}
        >
          Cancel
        </Button>
        <Button
          variant='contained'
          color='primary'
          style={{ borderRadius: 0, fontSize: '16px', textTransform: 'none' }}
          onClick={handleUpdateSite}
        >
          Updated
        </Button>
      </div>
    </div>
  )
}

export default PaymentApple
