import {Button, makeStyles} from '@material-ui/core'
import AddIcon from '@material-ui/icons/Add'
import SelectCustom from '../../components/select_custom'
import TableCustom from '../../components/table'
import {COLUMN_TABLE_USER} from '../../constants/column'
import {USER} from '../../apis/urlConfig'
import React, {useState} from 'react'

const useStyles = makeStyles({
  user_container: {
    '&>div:nth-of-type(1)': {
      margin: '2rem 0 0rem 0',
      display: 'flex',
      justifyContent: 'space-between',
      '&>div': {
        display: 'flex',
      },
    },
  },
})

const User = () => {
  const classes = useStyles()
  const [paramsHeader, setParamsHeader] = useState<{
    _sort: string
  }>({
    _sort: 'createdAt@desc',
  })
  return (
    <div className={classes.user_container}>
      <div>
        <div>
          <SelectCustom
            data={[
              {label: '정렬 기준: DESC', value: 'createdAt@desc'},
              {label: '정렬 기준: ASC', value: 'createdAt@asc'},
            ]}
            value={paramsHeader._sort}
            onChange={(e: any) => setParamsHeader({...paramsHeader, _sort: e})}
          />
          {/* <SelectCustom placeholder='연도별 보기' width='124px' /> */}
        </div>
        <Button variant='contained' color='primary'>
          <AddIcon />
          새로 추가
        </Button>
      </div>
      <p></p>
      <TableCustom
        column={COLUMN_TABLE_USER}
        select
        action
        url={USER}
        params={{accessFor: 'USER', ...paramsHeader}}
      />
    </div>
  )
}

export default User
