export enum TYPE_TRANSACTION {
  DEPOSIT = 'deposit',
  WITHDRAW = 'withdraw',
  PAYMENT = 'payment',
}

export enum TRANSACTION_STATUS {
  PENDING = 'PENDING',
  COMPLETE = 'COMPLETE',
  CANCEL = 'CANCEL',
}

export enum PAYMENT_METHOD {
  TRANSFER = 'TRANSFER',
  CREDIT_CARD = 'CREDIT_CARD',
}

export enum TYPE_TOPIC {
  OTHER_QUESTION = '추가 질문',
  CHECKING = '확인중',
  COMPLETE = '완료',
}

export enum IS_ACTIVE {
  PENDING = 'false',
  COMPLETE = 'true',
}

export enum TYPE_SORT {
  CREATED_AT_ASC = "createdAt@asc",
  CREATED_AT_DESC = "createdAt@desc",
  STATUS_AT_ASC = "status@asc",
  STATUS_AT_DESC = "status@desc",
}

export enum STATUS_SITE_UPDATE {
  PENDING = 'PENDING',
  CREATED = 'CREATED',
}

export enum STATUS_CONVERSATION {
  SEEN = 'SEEN',
  NOT_SEEN = 'NOT_SEEN',
}