export const LOGIN = '/user/login'
export const LOGOUT = ''

export const USER = '/user'
export const CONVERSATION = '/conversation'

export const MESSAGE = '/message'

export const TRANSACTION = '/transaction'

export const SITE = '/site'

export const GLOBAL = '/global'

export const REFRESH_TOKEN = '/user/refresh'