import React from 'react'
import {createRoot} from 'react-dom/client'
import {BrowserRouter} from 'react-router-dom'
import {Provider} from 'react-redux'
import {store} from './app/store'
import reportWebVitals from './reportWebVitals'
import Router from './router'
import {createTheme, ThemeProvider} from '@material-ui/core'
import AdminLayout from './layouts/admin'
import CustomizedSnackbars from './components/snackbar'
import 'grapesjs/dist/css/grapes.min.css'
import './index.scss'
import Loading from './components/loading'

const container = document.getElementById('root')!
const root = createRoot(container)

const theme = createTheme({
  typography: {
    fontFamily: ['Noto Sans KR'].join(','),
  },
  palette: {
    primary: {
      // light: will be calculated from palette.primary.main,
      main: '#2B83FE',
      // dark: will be calculated from palette.primary.main,
      // contrastText: will be calculated to contrast with palette.primary.main
    },
  },
  props: {
    MuiButton: {
      style: {
        height: '38px',
        fontSize: '14px',
        fontWeight: 500,
        lineHeight: '28px',
        borderRadius: '8px',
      },
    },
  },
})

console.log('check index');


root.render(
  <React.StrictMode>
    <Provider store={store}>
      <BrowserRouter>
        <ThemeProvider theme={theme}>
          <Loading />
          <AdminLayout>
            <CustomizedSnackbars />
            <Router />
          </AdminLayout>
        </ThemeProvider>
      </BrowserRouter>
    </Provider>
  </React.StrictMode>
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
