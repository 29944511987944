import PaymentApple from '../pages/apple'
import CustomerServiceCenter from '../pages/customer_service_center'
import EditorPage from '../pages/editor'
import PaymentGoogle from '../pages/google'
import Login from '../pages/login'
import PaymentManagement from '../pages/payment_magagement'
import ProjectManagement from '../pages/project_management'
import Transaction from '../pages/transaction'
import User from '../pages/user'
import WebInfo from '../pages/web_info'
import YoutubeLink from '../pages/youtube_link'
import MyRouteProp from './MyRouteProp'

export const ROUTE = {
  USER: '/',
  PAYMENT_MANAGEMENT: '/payment_management',
  TRANSACTION: '/transaction',
  PROJECT_MANAGEMENT: '/project_management',
  CUSTOMER_SERVICE_CENTER: '/customer_service_center',
  YOUTUBE_LINK: '/youtube_link',
  PAYMENT_APPLE: '/project_management/payment_apple',
  PAYMENT_APPLE_ID:'/project_management/payment_apple/:id',
  PAYMENT_GOOGLE: '/project_management/payment_google',
  PAYMENT_GOOGLE_ID: '/project_management/payment_google/:id',
  WEB_INFO: '/project_management/web_info',
  WEB_INFO_ID: '/project_management/web_info/:id',
  EDITOR: '/editor',
  LOGIN: '/login',
}

const routes: Array<MyRouteProp> = [
  {path: ROUTE.USER, element: <User />, private: true},
  {
    path: ROUTE.PAYMENT_MANAGEMENT,
    element: <PaymentManagement />,
    private: true,
  },
  {
    path: ROUTE.TRANSACTION,
    element: <Transaction />,
    private: true,
  },
  {
    path: ROUTE.PROJECT_MANAGEMENT,
    element: <ProjectManagement />,
    private: true,
  },
  {
    path: ROUTE.CUSTOMER_SERVICE_CENTER,
    element: <CustomerServiceCenter />,
    private: true,
  },
  {path: ROUTE.YOUTUBE_LINK, element: <YoutubeLink />, private: true},
  {path: ROUTE.WEB_INFO_ID, element: <WebInfo />, private: true},
  {path: ROUTE.PAYMENT_GOOGLE_ID, element: <PaymentGoogle />, private: true},
  {path: ROUTE.PAYMENT_APPLE_ID, element: <PaymentApple />, private: true},
  {path: ROUTE.EDITOR, element: <EditorPage />, private: true},
  {path: ROUTE.LOGIN, element: <Login />},
]
export default routes
