import { makeStyles } from '@material-ui/core'
import Button from '@material-ui/core/Button'
import KeyboardBackspaceIcon from '@material-ui/icons/KeyboardBackspace'
import infoCircle from '../../asset/icons/info_circle.svg'
import questionCircle from '../../asset/icons/question_circle.svg'
import InputBase from '../../components/input'
import Upload from '../../components/upload'
import { useNavigate, useParams } from 'react-router-dom'
import { ROUTE } from '../../router/routes'
import { useAppDispatch } from '../../app/hooks'
import { useEffect, useState } from 'react'
import { SiteType } from '../../types/site.type'
import { loadingActions } from '../../components/loading/loadingSlice'
import { STATUS_SITE_UPDATE } from '../../types/enum'
import axiosClient from '../../apis/axiosClient'
import { SITE } from '../../apis/urlConfig'
import { snackBarActions } from '../../components/snackbar/snackbarSlice'
import { globalActions } from '../../feature/global/globalSlice'

const useStyle = makeStyles({
  payment_google_container: {
    paddingBottom: '1rem',
    '&>p:nth-of-type(1)': {
      display: 'flex',
      fontSize: '18px',
      '&>span': {
        marginRight: '8px',
      },
    },
    '&>div:nth-of-type(1)': {
      padding: '1rem',
      background: 'rgba(235, 243, 255, 0.24)',
      border: '1px solid #EDEDED',
      borderRadius: '2px',

      '&>p:nth-of-type(1)': {
        display: 'flex',
        alignItems: 'center',
        '&>img': {
          marginRight: '8px',
        },
      },
      '&>p:nth-of-type(2)': {
        fontWeight: 400,
        fontSize: '14px',
        margin: 0,
      },
      '&>p:nth-of-type(3)': {
        fontWeight: 400,
        fontSize: '14px',
        margin: 0,
        '&>span': {
          color: '#2B83FE',
        },
      },
      '&>ul': {
        margin: 0,
        '&>li': {
          fontWeight: 400,
          fontSize: '14px',
        },
      },
    },

    '&>div:nth-of-type(2)': {
      marginTop: '1rem',
      padding: '24px',
      border: '1px solid #D0D5DD',
      borderRadius: '12px',
    },

    '&>div:nth-of-type(3)': {
      marginTop: '1rem',
      padding: '24px',
      border: '1px solid #D0D5DD',
      borderRadius: '12px',
    },

    '&>div:nth-of-type(4)': {
      marginTop: '1rem',
      padding: '24px',
      border: '1px solid #D0D5DD',
      borderRadius: '12px',
      '&>div:nth-of-type(1)': {
        display: 'flex',
        '&>div': {
          width: '50%',
          '&>p:nth-of-type(1)': {
            fontWeight: 400,
            margin: 0,
            display: 'flex',
            alignItems: 'center',
            '&>img': {
              marginLeft: '5px',
            },
          },
          '&>p:nth-of-type(2)': {
            fontWeight: 400,
            fontSize: '14px',
            color: '#70777F',
            marginTop: '5px',
          },
        },
      },
      '&>div:nth-of-type(2)': {
        marginTop: '2rem',
        '&>p:nth-of-type(1)': {
          fontWeight: 400,
          margin: 0,
          display: 'flex',
          alignItems: 'center',
          '&>img': {
            marginLeft: '5px',
          },
        },
        '&>p:nth-of-type(2)': {
          fontWeight: 400,
          fontSize: '14px',
          color: '#70777F',
          marginTop: '5px',
        },
        '&>p:nth-of-type(3)': {
          fontWeight: 400,
          fontSize: '14px',
          '&>span': {
            color: '#FD3535',
          },
        },
        '&>p:nth-of-type(4)': {
          fontWeight: 400,
          fontSize: '14px',
          color: '#70777F',
          marginTop: '5px',
        },
      },
    },
    '&>div:nth-of-type(5)': {
      marginTop: '1rem',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      gap: '32px',
    }
  },
  title_div: {
    marginTop: 0,
    marginBottom: 5,
  },
})

const PaymentGoogle = () => {
  const classes = useStyle()
  const { id } = useParams()
  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  const [site, setSite] = useState<SiteType>()

  console.log('Site Detail', site);
  console.log('id', id);

  const handleUpdateSite = async () => {
    try {
      dispatch(loadingActions.openLoading())
      const dataPatch: { key: string } = {
        key: 'androidInfo'
      }
      if (site?.androidInfo?.status === STATUS_SITE_UPDATE.CREATED) {
        console.log('Site update succeed')
        dispatch(loadingActions.loadingSuccess())
        navigate(ROUTE.PROJECT_MANAGEMENT)
      }
      else {
        axiosClient.patch(`${SITE}/update/${id}/active-info`, dataPatch)
          .then((res) => {
            console.log('Site update succeed:', res)
            dispatch(loadingActions.loadingSuccess())

            dispatch(snackBarActions.setStateSnackBar({
              content: '성공',
              type: 'success',
            }))
            dispatch(globalActions.getList({ params: undefined }))
            navigate(ROUTE.PROJECT_MANAGEMENT)
          })
          .catch((error: any) => {
            console.log(error)
            dispatch(loadingActions.loadingSuccess())
            dispatch(snackBarActions.setStateSnackBar({
              content: '실패',
              type: 'error',
            }))
          })
      }

    } catch (error) {
      console.log('error:', error)
      dispatch(loadingActions.loadingSuccess())
      dispatch(snackBarActions.setStateSnackBar({
        content: '실패',
        type: 'error',
      }))
    }
  }

  useEffect(() => {
    const getDetailConversation = async () => {
      try {
        dispatch(loadingActions.openLoading())
        const data: { data: SiteType } = await axiosClient.get(
          `${SITE}/get/${id}`
        )
        setSite(data.data)
        dispatch(loadingActions.loadingSuccess())
      } catch (error) {
        console.log(error)
        dispatch(loadingActions.loadingSuccess())
      }
    }
    getDetailConversation()
  }, [id])

  return (
    <div className={classes.payment_google_container}>
      <p>
        <span onClick={() => navigate(ROUTE.PROJECT_MANAGEMENT)}>
          <KeyboardBackspaceIcon />
        </span>
        구글플레이 등록 및 수정
      </p>

      <div>
        <p>
          <img src={infoCircle} alt='' /> 주의
        </p>
        <p>
          Google Play에서는 아래와 같은 정책을 시행하고 있으며, 이를 준수하지
          않을 경우 App이 강제로 삭제되거나 개발자 계정이 정지될 수
          있습니다.Google 정책에 의해 삭제된 앱에 대해서는 지엠포컴퍼니가이
          책임지지 않으므로 Google에서 개발자 계정 메일로 보내주는 정책 알림을
          수시로 확인하여 대응하셔야 합니다.
        </p>
        <ul>
          <li>음란물 : 성매매 또는 유흥 등 성인용 컨텐츠</li>
          <li>명의도용 또는 사기행위 : 모조품 판매 또는 연결 컨텐츠</li>
          <li>
            제휴사 트래픽 유도 : App의 주 목적이 제휴사 트래픽을 웹사이트로
            유도하는 것(주요 메뉴들이 외부 웹사이트로 연결되어 있는 경우)
          </li>
          <li>
            스팸성 키워드 : App 제목이나 설명에 관련 없는 키워드나 설명을
            나열하는 행위
          </li>
          <li>
            상표권 도용 : 등록된 상표를 무단으로 App 아이콘에 사용하는 경우
          </li>
          <li>
            저작권 침해 : 영화 포스터 등 저작권이 있는 이미지를 홈화면, 그래픽
            이미지, 앱 아이콘, 캡쳐화면, 시작화면 이미지로 설정하는 경우
          </li>
          <li>
            컨텐츠 수위 : 주류, 담배, 국제결혼 등을 취급하는 App에서 전체이용가
            등으로 설정하는 경우
          </li>
          <li>
            개인정보처리방침 누락/불일치 : 앱의 각종 권한, 회원가입,
            위치정보사용 등 취급하는 개인정보에 대해 개인정보처리방침에 명시하고
            Google Play 앱 등록정보에 URL을 기재해야 합니다. 보다 자세한 사항은
            구글 플레이 개발자 정책을 참고하시기 바랍니다.
          </li>
        </ul>
      </div>
      <div>
        <p className={classes.title_div}>구글플레이 계정정보</p>
        <InputBase
          label='아이디'
          onChange={() => { }}
          placeholder='입력해주세요.'
          style={{ width: '656px', height: '44px' }}
          value={site?.androidInfo?.user}
          disabled
        />
        <InputBase
          label='비밀번호'
          onChange={() => { }}
          placeholder='입력해주세요.'
          style={{ width: '656px', height: '44px' }}
          value={site?.androidInfo?.password}
          disabled
        />
      </div>

      <div>
        <p className={classes.title_div}>앱 기본정보</p>
        <InputBase
          label='앱 제목'
          onChange={() => { }}
          placeholder='입력해주세요.'
          style={{ width: '656px', height: '44px' }}
          value={site?.androidInfo?.appName}
          disabled
        />
        <p>* 앱 설치 후 아이콘 아래에 표시되는 이름입니다.</p>
      </div>

      <div>
        <p className={classes.title_div}>그래픽 저작물</p>
        <div>
          <div>
            <p>
              고해상도 아이콘 가로세로 1024x1024{' '}
              <img src={questionCircle} alt='' />
            </p>
            <p>32비트 PNG(알파 없음), 모서리는 자동으로 둥글게 처리됩니다.</p>
            <Upload style={{ width: '358px', height: '358px' }} img={site?.androidInfo?.icon} />
          </div>
          <div>
            <p>
              시작화면 (선택사항) <img src={questionCircle} alt='' />
            </p>
            <p>가로x세로 1440x2960 JPG또는 24비트 PNG(알파 없음)</p>
            <Upload style={{ width: '175px', height: '358px' }} img={site?.androidInfo?.homeScreen} />
          </div>
        </div>
        <div>
          <p>
            알림 아이콘(선택사항) <img src={questionCircle} alt='' />
          </p>
          <p>권장 해상도: 96x96 / PNG / 배경 투명으로, 흰색으로 표현</p>
          <p>
            <span>안내:</span> 안드로이드 5.0 이상부터 유채색 알림 아이콘을
            지원하지 않아 단색 아이콘을 첨부하셔야 합니다. 자세히 알아보기
          </p>
          <Upload
            style={{ width: '96px', height: '96px' }}
            textButton='PNG 파일 업로드'
            img={site?.androidInfo?.notificationIcon}
          />
          <p>
            *알림 아이콘은 앱에서 알림이 왔을때 상단에 보여지는 아이콘입니다.
          </p>
        </div>
      </div>
      <div>
        <Button
          variant='outlined'
          style={{ borderRadius: 0, fontSize: '16px', textTransform: 'none' }}
          onClick={() => navigate(ROUTE.PROJECT_MANAGEMENT)}
        >
          Cancel
        </Button>
        <Button
          variant='contained'
          color='primary'
          style={{ borderRadius: 0, fontSize: '16px', textTransform: 'none' }}
          onClick={handleUpdateSite}
        >
          Updated
        </Button>
      </div>
    </div>
  )
}

export default PaymentGoogle
