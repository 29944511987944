import {LoginType} from '../types/login.type'

interface TypeArray {
  _id: string
  [key: string]: any
}

export const numberWithCommas = (x: number): string => {
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
}

export const setTokens = (token: LoginType) => {
  localStorage.setItem('accessToken', token.accessToken)
  localStorage.setItem('refreshToken', token.refreshToken)
  localStorage.setItem('expiresIn', JSON.stringify(token.expiresIn))
}

export function formatPhoneNumber(phoneNumberString: any) {
  var cleaned = ('' + phoneNumberString).replace(/\D/g, '')
  var match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/)
  if (match) {
    var intlCode = match[1] ? '+1 ' : ''
    return [intlCode, '(', match[2], ') ', match[3], '-', match[4]].join('')
  }
  return null
}

export const mereListById = (array1: TypeArray[], array2: TypeArray[]) => {
  console.log('mereListById')
  const set1 = new Set(array1.map((item) => item._id))
  const array3 = array2.filter((item) => !set1.has(item._id))
  return [...array1, ...array3]
}
