import React from 'react'
import Editor from '../../components/editor'

const EditorPage = () => {
  return (
    <div>
      <Editor />
    </div>
  )
}

export default EditorPage
