import {
  Button,
  Checkbox,
  IconButton,
  Modal,
  Theme,
  Tooltip,
  withStyles,
} from '@material-ui/core'
import { Pagination } from '@material-ui/lab'
import { makeStyles } from '@material-ui/styles'
import seeIcon from '../../asset/images/see.png'
import updateIcon from '../../asset/images/update.png'
import deleteIcon from '../../asset/images/delete.png'
import closeIcon from '../../asset/images/cancel.png'
import infoCircle from '../../asset/images/iconInfoCircle.png'
import arrowRightUpIcon from '../../asset/icons/arrow_right_up.svg'
import chPlayIcon from '../../asset/icons/ch_play.svg'
import appleIcon from '../../asset/icons/apple.svg'
import React, { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { ROUTE } from '../../router/routes'
import axiosClient from '../../apis/axiosClient'
import { BASE_URL, DATA_TRANSACTION } from '../../constants'
import { SITE, TRANSACTION, USER } from '../../apis/urlConfig'
import { useAppDispatch } from '../../app/hooks'
import { loadingActions } from '../loading/loadingSlice'
import DeleteIcon from '@material-ui/icons/Delete'
import { snackBarActions } from '../snackbar/snackbarSlice'
import { numberWithCommas } from '../../utils'
import moment from 'moment'
import CHPlay from '../../asset/icons/ch_play'
import Apple from '../../asset/icons/apple'
import InfoCircle from '../../asset/icons/info_circle'
import { STATUS_SITE_UPDATE } from '../../types/enum'
import { globalActions } from '../../feature/global/globalSlice'

const useStyles = makeStyles({
  table_container: {
    '&>table': {
      borderCollapse: 'collapse',
      width: '100%',
      // minHeight: '700px',
      '&>thead': {
        background: '#F2F2F2',
        // borderRadius: '8px',
        borderRadius: '12px 12px 0px 0px',
        '&>tr': {
          textAlign: 'start',
          '&>th': {
            textAlign: 'left',
            fontWeight: 700,
            fontSize: '12px',
            padding: '8px',
            color: '#70777F',
          },
        },
      },
      '&>tbody': {
        '&>tr': {
          '&>td': {
            padding: '8px',
            fontWeight: 400,
            fontSize: '14px',
            textAlign: 'left',
            color: '#272B30',
          },
        },
      },
    },
    '&>div': {
      padding: '1rem',
      borderTop: '1px solid #F2F2F2',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      '&>div': {
        fontSize: '14px',
        '&>span': {
          padding: '0.3rem 0.5rem',
          border: '1px solid #EDEDED',
          borderRadius: '8px',
          margin: '0 0.5rem',
        },
      },
    },
  },
  action_img_style: {
    width: '34px',
    height: '34px',
    marginRight: '0.5rem',
  },
  style_icon: {
    marginRight: '2rem',
  },
  status_complete: {
    display: 'flex',
    width: '76px',
    boxSizing: 'border-box',
    background: '#E3FFF1',
    borderRadius: '10px',
    padding: '4px 8px',
    alignItems: 'center',
    gap: '4px',
    '&>div': {
      backgroundColor: '#06C270',
      height: '5px',
      width: '5px',
      borderRadius: '50%',
    },
    '&>p': {
      margin: '0',
      padding: 0,
      fontSize: '12px',
      color: '#06C270',
      textAlign: 'center',
    }

  },
  status_pending: {
    display: 'flex',
    width: '76px',
    boxSizing: 'border-box',
    background: '#FFF8E5',
    borderRadius: '10px',
    padding: '4px 8px',
    alignItems: 'center',
    gap: '4px',
    cursor: 'pointer',
    '&>div': {
      backgroundColor: '#FF8800',
      height: '5px',
      width: '5px',
      borderRadius: '50%',
    },
    '&>p': {
      margin: '0',
      padding: 0,
      fontSize: '12px',
      color: '#FF8800',
      textAlign: 'center',
    }
  },
  active: {
    display: 'flex',
    width: '50px',
    boxSizing: 'border-box',
    background: '#EAFAE5',
    borderRadius: '10px',
    padding: '4px 8px',
    alignItems: 'center',
    justifyContent: 'center',
    '&>p': {
      margin: '0',
      padding: 0,
      fontSize: '12px',
      fontWeight: 500,
      color: '#00BF71',
      textAlign: 'center',
    }
  },
  inActive: {
    display: 'flex',
    width: '50px',
    boxSizing: 'border-box',
    background: '#2B83FE',
    borderRadius: '10px',
    padding: '4px 8px',
    alignItems: 'center',
    cursor: 'pointer',
    '&>p': {
      margin: '0',
      padding: 0,
      fontSize: '12px',
      fontWeight: 500,
      color: '#FFFFFF',
      textAlign: 'center',
    }
  },
  modal: {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    backgroundColor: '#fff',
    borderRadius: '12px',
    boxShadow: '0 0 12px 0 rgba(0, 0, 0, 0.25)',
    border: 'none',
    '&>div:nth-of-type(1)': {
      display: 'flex',
      padding: '16px 32px',
      justifyContent: 'space-between',
      alignItems: 'center',
      borderBottom: '1px solid #EDEDED',
      textAlign: 'center',
      '&>p': {
        padding: 0,
        margin: 0,
        fontSize: '20px',
        fontWeight: 500,
        textAlign: 'center',
      },
      '&>img': {
        cursor: 'pointer',
        height: '24px',
        width: '24px',
      },
    },
    '&>div:nth-of-type(2)': {
      display: 'flex',
      flexDirection: 'column',
      gap: '12px',
      padding: '12px 30px 0 30px',
      alignItems: 'center',
      '&>div:nth-of-type(1)': {
        width: 'calc(470px)',
        display: 'flex',
        padding: '16px',
        justifyContent: 'space-between',
        border: '1px solid #2B83FE',
        borderRadius: '8px',
        '&>p': {
          padding: 0,
          margin: 0,
          fontSize: '14px',
          fontWeight: 700,
          color: '#70777F',
        },
        '&>div': {
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'flex-end',
          '&>p': {
            padding: 0,
            margin: 0,
            fontSize: '18px',
            fontWeight: 700,
            color: '#272B30',
          },
        },
      },
      '&>div:nth-of-type(2)': {
        width: 'calc(502px)',
        paddingBottom: '12px',
        '&>div': {
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          padding: '18px 0',
          borderBottom: '1px solid #EDEDED',
          '&>p:nth-of-type(1)': {
            padding: 0,
            margin: 0,
            fontSize: '14px',
            lineHeight: '20px',
            fontWeight: 500,
            color: '#70777F',
          },
          '&>p:nth-of-type(2)': {
            padding: 0,
            margin: 0,
            fontSize: '16px',
            fontWeight: 500,
            color: '#111315',
          },
        },
        '&>div:nth-of-type(3)': {
          borderBottom: 'none',
        },
      },
    },
    '&>div:nth-of-type(3)': {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      margin: '0 13px',
      padding: '16px 32px',
      gap: '16px',
      borderTop: '1px solid #EDEDED',

      '&>button': {
        width: '100%',
        justifyContent: 'center',
        alignItems: 'center',
        borderRadius: '8px',
        border: '1px solid #D0D5DD',
        backgroundColor: '#fff',
        padding: '10px 24px',
        textAlign: 'center',
        cursor: 'pointer',
        '&>p': {
          padding: 0,
          margin: 0,
          fontSize: '16px',
          fontWeight: 500,
          color: '#272B30',
        },
      },
      '&>button:nth-of-type(2)': {
        backgroundColor: '#2B83FE',
        '&>p': {
          color: '#fff',
        },
      },
    },
  },
  button_request_modification: {
    display: 'flex', alignItems: 'center', justifyContent: 'center',
    '&>button': {
      display: 'flex',
      alignItems: 'center',
      cursor: 'pointer',
      border: '.5px solid #D0D5DD ',
      padding: '10px 12px',
      color: '#343941',
      fontSize: '14px',
      fontWeight: 400,
      gap: '2px',
      background: 'transparent',
      '&:disabled': {
        color: '#EDEDED',
      },
      '&>div': {
        height: '6px',
        width: '6px',
        borderRadius: '50%',
      },
      '&>img': {
        width: '20px',
        height: '20px',
      },
    },
  }
})
// const Tooltip = withStyles((theme: Theme) => ({
//   tooltip: {
//     // fontSize: 14,
//     // fontWeight: 400,
//   },
// }))(Tooltip)

const TableCustom = (props: {
  column: {
    title: string
    dataIndex: string
    render: any
    sort: boolean
  }[]
  url?: string
  style?: React.CSSProperties
  select?: boolean
  action?: boolean
  hideSee?: boolean
  hideUpdate?: boolean
  hideDelete?: boolean
  params?: any
  isReload?: boolean
}) => {
  const classes = useStyles()
  const navigate = useNavigate()
  const [data, setData] = useState<any[]>([])

  console.log('data1111', data);

  const [totalData, setTotalData] = useState<number>()
  const [reLoad, setReLoad] = useState<boolean>(true)
  const [selectAll, setSelectAll] = useState<boolean>(false)
  const [selectListItem, setSelectListItem] = useState<string[]>([])
  const [page, setPage] = useState<number>(1)
  const dispatch = useAppDispatch()

  const [open, setOpen] = useState<boolean>(false)
  const [dataModal, setDataModal] = useState<any>({})

  const [showValue, setShowValue] = useState(true);

  const handleClose = () => {
    setOpen(false)
  }

  const handleConfirmSite = async () => {
    try {
      await axiosClient.patch(`${props.url}/update/${dataModal._id}/active`)
      setOpen(false)
      setReLoad(true)
      dispatch(
        snackBarActions.setStateSnackBar({
          content: 'success',
          type: 'success',
        })
      )
      console.log('data', data);
    }
    catch (error) {
      console.log(error)
      setOpen(false)
      setReLoad(true)
      dispatch(
        snackBarActions.setStateSnackBar({
          content: 'error',
          type: 'error',
        })
      )
    }
  }

  const handleDelete = async (id: string) => {
    if (window.confirm('삭제 확인?')) {
      try {
        await axiosClient.delete(`${props.url}/delete/${id}`)
        setReLoad(true)
        dispatch(
          snackBarActions.setStateSnackBar({
            content: 'success',
            type: 'success',
          })
        )
      } catch (error) {
        dispatch(
          snackBarActions.setStateSnackBar({
            content: 'error',
            type: 'error',
          })
        )
      }
    }
  }

  const handleSelectItem = (id: string) => {
    if (selectListItem.includes(id)) {
      setSelectListItem([...selectListItem.filter((item) => item !== id)])
    } else {
      setSelectListItem([...selectListItem, id])
    }
  }
  const renderStatusTransaction = (data: any) => {
    return (
      <div
        onClick={async () => {
          if (
            data.status === 'PENDING'
            && window.confirm('change status transaction?')
          ) {
            await axiosClient.put(`${TRANSACTION}/approve/${data._id}`)
            dispatch(globalActions.getList({ params: undefined }))
            dispatch(
              snackBarActions.setStateSnackBar({
                content: 'success',
                type: 'success',
              })
            )
            setReLoad(true)
          }
        }}
        className={
          data.status === 'COMPLETE'
            ? classes.status_complete
            : data.status === 'PENDING'
              ? classes.status_pending
              : ''
        }
      >
        <div />
        <p>
          {data.status === 'COMPLETE'
            ? 'Success'
            : data.status === 'PENDING'
              ? 'Pending'
              : ''}
        </p>
      </div>
    )
  }

  const renderStatusSite = (data: any) => {
    return (
      <div
        onClick={() => {
          if (
            data.isActive === false
          ) {
            setOpen(true)
            setDataModal(data)
          }
        }}
        className={data.isActive ? classes.active : classes.inActive}
      >
        <p>
          {data.isActive ? '완료' : '확인중'}
        </p>
      </div>
    )
  }
  const handleDeletes = async () => {
    if (window.confirm('삭제 확인?')) {
      try {
        await axiosClient.delete(`${props.url}/delete`, {
          data: { ids: selectListItem },
        })
        setReLoad(true)
        setSelectListItem([])
        dispatch(
          snackBarActions.setStateSnackBar({
            content: 'success',
            type: 'success',
          })
        )
      } catch (error) {
        dispatch(
          snackBarActions.setStateSnackBar({
            content: 'error',
            type: 'error',
          })
        )
      }
    }
  }

  const CustomEndDateCell = (props: any) => {
    const { expirationDate, remainingDays } = props;
    const remainingDaysFloor = Math.floor(remainingDays)
    return (
      <div style={{ cursor: 'pointer' }} onClick={() => setShowValue(!showValue)}>
        {showValue ? (
          remainingDaysFloor === 0 ? (
            <span>{remainingDaysFloor} 만료</span>
          ) : (
            <span>{remainingDaysFloor}일 남음</span>
          )
        ) : (
          <span>{moment(expirationDate).format('YYYY/MM/DD')}</span>
        )}
      </div>
    );
  };

  const handleRequestModification = (itemData: any, item: any, indexData: number) => {
    if (indexData === 0) {
      console.log('handleRequestModification');
      console.log('itemData', itemData);
    }

    const { webInfo, androidInfo, iosInfo } = itemData;

    return (
      <div className={classes.button_request_modification}>
        <button
          onClick={() => navigate(`${ROUTE.WEB_INFO}/${itemData._id}`)}
          disabled={!webInfo}
        >
          <div
            style={(webInfo?.status === STATUS_SITE_UPDATE.PENDING) ? { backgroundColor: '#2B83FE' } : {}}
          />
          {/* <img src={infoCircle} alt='' /> */}
          <InfoCircle color={!(webInfo) ? '#EDEDED' : ''} />
          정보
        </button>

        <button
          onClick={() => navigate(`${ROUTE.PAYMENT_GOOGLE}/${itemData._id}`)}
          style={{
            borderTop: '.5px solid #D0D5DD ',
            borderBottom: '.5px solid #D0D5DD ',
            borderLeft: 'none',
            borderRight: 'none',
          }}
          disabled={!androidInfo}
        >
          <div
            style={(androidInfo?.status === STATUS_SITE_UPDATE.PENDING) ? { backgroundColor: '#2B83FE' } : {}}
          />
          {/* <img src={chPlayIcon} alt='' /> */}
          <CHPlay color={!(androidInfo) ? '#EDEDED' : ''} />
          신청
        </button>

        <button
          onClick={() => navigate(`${ROUTE.PAYMENT_APPLE}/${itemData._id}`)}
          disabled={!iosInfo}
        >
          <div
            style={(iosInfo?.status === STATUS_SITE_UPDATE.PENDING) ? { backgroundColor: '#2B83FE' } : {}}
          />
          {/* <img src={appleIcon} alt='' /> */}
          <Apple color={!(iosInfo) ? '#EDEDED' : ''} />
          신청
        </button>
      </div>
    )
  }

  useEffect(() => {
    const getData = async () => {
      dispatch(loadingActions.openLoading())
      try {
        const data: {
          data: any[]
          totalData: number
        } = await axiosClient.get(`${BASE_URL}${props.url}/list`, {
          params: { ...props.params, page },
        })
        setData(data.data)
        setTotalData(data.totalData)
        setReLoad(false)
        dispatch(loadingActions.loadingSuccess())
      } catch (error) {
        dispatch(loadingActions.loadingSuccess())
      }
    }
    getData()
  }, [props.url, props.params, reLoad, dispatch, page, props.isReload])
  return (
    <div className={classes.table_container}>
      {selectListItem.length > 0 &&
        (<Button
          style={props.url === USER ?
            { backgroundColor: 'red', color: 'white', position: 'absolute', right: '160px', marginTop: '-54px', height: '38px' } :
            { backgroundColor: 'red', color: 'white', position: 'absolute', right: '32px', marginTop: '-54px', height: '38px' }
          }
          onClick={handleDeletes}
        >
          <DeleteIcon /> 삭제
        </Button>)}
      <table>
        <thead>
          <tr>
            {props.select && (
              <Checkbox
                checked={selectAll}
                onClick={() => {
                  if (!selectAll) {
                    setSelectAll(true)
                    setSelectListItem([...data.map((item) => item._id)])
                  } else {
                    setSelectAll(false)
                    setSelectListItem([])
                  }
                }}
                name='checkedB'
                color='primary'
              />
            )}
            {props.column.map((item) => {
              if (item.dataIndex === 'site') return <th style={{ textAlign: 'center' }}>{item.title} </th>
              else if (item.dataIndex !== 'id') return <th>{item.title} </th>
            })}
            {props.action && <th>분야</th>}
          </tr>
        </thead>
        <tbody>
          {data.map((itemData: any, indexData: number) => (
            <tr>
              {props.select && (
                <Checkbox
                  checked={selectListItem.includes(itemData._id)}
                  onClick={() => {
                    handleSelectItem(itemData._id)
                  }}
                  name='checkedB'
                  color='primary'
                />
              )}

              {props.column.map((item) => {
                if (item.dataIndex === 'site') {
                  return (
                    <td>
                      {handleRequestModification(itemData, item, indexData)}
                    </td>
                  )
                }
                else if (item.dataIndex === 'remainingDays') {
                  return <td>{CustomEndDateCell(itemData)}</td>
                }
                else if (
                  item.dataIndex === 'status' &&
                  props.url === TRANSACTION
                ) {
                  return <td>{renderStatusTransaction(itemData)}</td>
                }
                else if (
                  item.dataIndex === 'status' &&
                  props.url === SITE
                ) {
                  return <td>{renderStatusSite(itemData)}</td>
                }
                else if (
                  item.dataIndex === 'accountNumber' &&
                  props.url === TRANSACTION
                ) {
                  return <td>1112324342352</td>
                }
                else if (
                  item.dataIndex === 'webInfo') {
                  return (
                    <td>{item.render(itemData)}</td>
                  )
                }
                else {
                  if (item.dataIndex !== 'id')
                    return (
                      <td>{item.render(itemData[`${item.dataIndex}`])}</td>
                    )
                }
              })}
              {props.action && (
                <td>
                  <div style={{ display: 'flex' }}>
                    {!props.hideSee && (
                      <Tooltip title='이력서 보기' arrow placement='top'>
                        <img
                          className={classes.action_img_style}
                          src={seeIcon}
                          alt=''
                        />
                      </Tooltip>
                    )}
                    {!props.hideUpdate && (
                      <Tooltip title='편집하다' arrow placement='top'>
                        <img
                          className={classes.action_img_style}
                          src={updateIcon}
                          alt=''
                        />
                      </Tooltip>
                    )}
                    {!props.hideDelete && (
                      <Tooltip
                        title='삭제'
                        arrow
                        placement='top'
                        onClick={() => handleDelete(itemData._id)}
                      >
                        <img
                          className={classes.action_img_style}
                          src={deleteIcon}
                          alt=''
                        />
                      </Tooltip>
                    )}
                  </div>
                </td>
              )}
            </tr>
          ))}
        </tbody>
      </table>
      <div>
        <div>
          쇼 <span>10</span>총 {totalData}개 항목 중
        </div>

        <Pagination
          count={Math.ceil(Number(totalData) / 10)}
          shape='rounded'
          onChange={(event: object, page: number) => {
            setPage(page)
            setReLoad(true)
          }}
        />
      </div>

      <Modal
        open={open}
        onClose={handleClose}
        disableAutoFocus={true}
      >
        <div className={classes.modal}>
          <div>
            <p>문의 작성</p>
            <img src={closeIcon} alt='close' onClick={handleClose} />
          </div>
          <div>
            <div>
              <p>결제 금액</p>
              <div>
                <p>{numberWithCommas(dataModal?.price || 0)}원</p>
                <p>(신용카드)</p>
              </div>
            </div>
            <div>
              <div>
                <p>아이디</p>
                <p>{dataModal?.adminEmail || ''}</p>
              </div>

              <div>
                <p>결제 테마</p>
                <p>{dataModal?.theme?.name || ''}</p>
              </div>

              <div>
                <p>결제일</p>
                <p>{moment(dataModal?.createdAt).format('YYYY/MM/DD') || ''}</p>
              </div>
            </div>
          </div>
          <div>
            <button onClick={handleClose}>
              <p>취소</p>
            </button>
            <button onClick={handleConfirmSite}>
              <p>생성</p>
            </button>
          </div>
        </div>
      </Modal>
    </div>
  )
}

export default TableCustom
