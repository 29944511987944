import {makeStyles} from '@material-ui/core'

const useStyles = makeStyles({
  container_input_base: {
    width: '100%',
    position: 'relative',
    display: 'flex',
    alignItems: 'start',
    marginBottom: '1rem',
    '&>label': {
      display: 'flex',
      fontWeight: '500',
      fontSize: '14px',
      lineHeight: '27px',
      color: '#1F293',
      width: '20%',
      marginRight: '3rem',
      justifyContent: 'end',
      '&>span': {
        color: '#F22828',
      },
      '&>img': {
        marginLeft: 5,
      },
    },
    '&>input': {
      width: '100%',
      height: '44px',
      fontFamily: 'Pretendard',
      padding: '10px',
      border: '1px solid #D0D5DD',
      borderRadius: '8px',
      boxSizing: 'border-box',
      fontSize: '14px',
    },
    '&>textarea': {
      border: '1px solid #D0D5DD',
      borderRadius: '8px',
      fontSize: '14px',
      padding: '10px',
      boxSizing: 'border-box',
    },
    '&>span': {
      position: 'absolute',
      top: '54px',
      right: '13px',
    },
  },
})

const InputCustom = (props: {
  label: string
  placeholder: string
  require?: boolean
  onChange: (event: any) => void
  type?: 'text' | 'number' | 'password' | 'email'
  icon?: any
  style?: React.CSSProperties
  value?: string | number
  disabled?: boolean
  iconLabel?: string
  textArea?: boolean
}) => {
  const classes = useStyles()
  return (
    <div className={classes.container_input_base}>
      {props.label && (
        <>
          <label htmlFor={props.label}>
            {props.label} {props.require && <span>*</span>}
            {props.iconLabel && <img src={props.iconLabel} alt='' />}
          </label>
          <br />
        </>
      )}
      {!props.textArea ? (
        <input
          disabled={props.disabled}
          style={props.style ? {...props.style} : {}}
          type={props.type ? props.type : 'text'}
          id={props.label}
          placeholder={props.placeholder}
          required={props.require}
          onChange={(e) => props.onChange(e.target.value)}
          value={props.value}
        />
      ) : (
        <textarea
          disabled={props.disabled}
          style={props.style ? {...props.style} : {}}
          id={props.label}
          placeholder={props.placeholder}
          required={props.require}
          onChange={(e) => props.onChange(e.target.value)}
          value={props.value}
        />
      )}

      {props.icon && (
        <span>
          <>{props.icon}</>
        </span>
      )}
    </div>
  )
}

export default InputCustom
