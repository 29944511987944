import {PayloadAction, createSlice} from '@reduxjs/toolkit'
import {NavigateFunction} from 'react-router-dom'

const initialState = {}

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    login: (
      state,
      action: PayloadAction<{
        data: {username: string; password: string}
        history: NavigateFunction
      }>
    ) => {},
  },
})

export const authReducer = authSlice.reducer
export const authAction = authSlice.actions