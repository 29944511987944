import { Modal, makeStyles } from '@material-ui/core'
import MoreHorizIcon from '@material-ui/icons/MoreHoriz'
import moment from 'moment'
import { useEffect, useRef, useState } from 'react'
import { io } from "socket.io-client"
import axiosClient from '../../apis/axiosClient'
import { CONVERSATION, MESSAGE } from '../../apis/urlConfig'
import { useAppDispatch, useAppSelector } from '../../app/hooks'
import SendIcon from '../../asset/icons/send'
import arrowIcon from '../../asset/images/arrow.png'
import noneMessage from '../../asset/images/MessagesDetailNone.png'
import tuningIcon from '../../asset/images/tuning.png'
import userDefault from '../../asset/images/userDefault.png'

import CardQuestion from '../../components/card_question'
import InputBase from '../../components/input'
import { loadingActions } from '../../components/loading/loadingSlice'
import InfiniteScroll from 'react-infinite-scroll-component'
import {
  conversationActions,
  selectListData,
  selectTotalData,
} from '../../feature/conversation/conversationSlice'
import { ConversationDetailType } from '../../types/conversationDetail.type'
import { ConversationDetailMessageType } from '../../types/conversationDetailMessage.type'
import { STATUS_CONVERSATION, TYPE_SORT, TYPE_TOPIC } from '../../types/enum'
import { globalActions } from '../../feature/global/globalSlice'
import { snackBarActions } from '../../components/snackbar/snackbarSlice'
import { ConversationType } from '../../types/conversation.type'

const useStyles = makeStyles({
  question_container: {
    display: 'flex',
    justifyContent: 'space-between',
    '&>div:nth-of-type(1)': {
      width: '35%',
      padding: '1rem 0',
      boxSizing: 'border-box',
      maxHeight: 'calc(100vh - 78px)',
      border: '1px solid #D0D5DD',
      // overflow: 'auto',
      '&>div:nth-of-type(1)': {
        display: 'flex',
        justifyContent: 'space-between',
        padding: '0.5rem 1rem 1.5rem 1rem',
        borderBottom: '1px solid #D0D5DD',
        '&>span:nth-of-type(1)': {
          fontWeight: 700,
          fontSize: '24px',
          display: 'flex',
          alignItems: 'center',
        },
        '&>span:nth-of-type(2)': {
          fontWeight: 500,
          fontSize: '14px',
          padding: '8px 16px',
          display: 'flex',
          border: '0.5px solid #B1B5C4',
          borderRadius: '10px',
          '&>img': {
            width: '20px',
            height: '20px',
          },
        },
      },
      '&>div:nth-of-type(2)': {
        maxHeight: 'calc(100vh - 78px - 90px)',
        overflow: 'auto',
        padding: '1rem',
        boxSizing: 'border-box',
      },
    },
    '&>div:nth-of-type(2)': {
      width: '65%',
      background: 'white',
      height: 'calc(100vh - 78px)',
      border: '1px solid #D0D5DD',
      position: 'relative',
      '&>div:nth-of-type(1)': {
        borderBottom: '1px solid #B1B5C4',
        padding: '1.5rem 2rem',
        '&>div': {
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          '&>div': {
            display: 'flex',
            '&>img': {
              width: '60px',
              height: '60px',
              borderRadius: '50%',
              marginRight: '1rem',
            },
            '&>div': {
              '&>p:nth-of-type(1)': {
                margin: 0,
                marginBottom: '10px',
                fontWeight: 700,
                fontSize: '18px',
              },
              '&>p:nth-of-type(2)': {
                margin: 0,
                color: '#272B30',
                fontSize: '14px',
                fontWeight: 400,
              },
            },
          },
          '&>span': {
            display: 'flex',
            boxSizing: 'border-box',
            padding: '4px',
            border: '0.5px solid #B1B5C4',
            borderRadius: '10px',
            width: '32px',
            height: '32px',
          },
        },
        '&>p': {
          color: '#2D2F31',
          marginBottom: 0,
        },
      },
      '&>div:nth-of-type(2)': {
        maxHeight: 'calc(100vh - 318px)',
        overflow: 'auto',
      },
      '&>div:nth-last-child(1)': {
        position: 'absolute',
        padding: '1.5rem 2rem',
        display: 'flex',
        alignItems: 'center',
        borderTop: '1px solid #3B71FE',
        bottom: 0,
        left: 0,
        right: 0,
        '&>img': {
          width: '32px',
          height: '32px',
          marginRight: '0.5rem',
        },
      },
    },
  },
  noneMessage: {
    display: 'flex',
    height: '100%',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    gap: '24px',
    '&>img': {
      height: '240px !important',
      width: '240px !important',
      boxShadow: 'none !important',
      borderRadius: 'none !important',
    },
    '&>p': {
      fontSize: '18px',
      fontWeight: 500,
      color: '#70777F',
      margin: 0,
      padding: 0,
    }
  },
  active_tab: {
    background: '#FFFFFF',
    borderRadius: '12px',
    fontSize: '18px',
    color: '#000000',
  },
  no_active_tab: {
    fontSize: '18px',
    color: '#777E91',
  },
  message_user_container: {
    display: 'flex',
    padding: '0 2rem',
    marginTop: '1rem',
    marginLeft: '60px',
    borderLeft: '2px solid #DCE1E7',
    position: 'relative',
    '&>img:nth-of-type(1)': {
      width: '60px',
      height: '60px',
      borderRadius: '50%',
      marginRight: '1rem',
    },
    '&>div': {
      '&>p:nth-of-type(1)': {
        fontSize: '14px',
        marginBottom: '0',
      },
      '&>p:nth-of-type(2)': {
        fontSize: '12px',
        fontWeight: 400,
        color: 'rgba(45, 47, 49, 0.5)',
        margin: 0,
      },
      '&>div': {
        display: 'flex',
        alignItems: 'center',
        gap: '8px',
        margin: '12px 0 12px 0',
        '&>div': {
          '&>img': {
            height: '52px',
            width: '44px',
            borderRadius: '4px',
          }
        },
      },
      '&>p:nth-of-type(3)': {
        // width: '70%',
        margin: '10px 0',
        fontSize: '14px',
        marginTop: 0,
        fontWeight: 400,
        color: '#666666',
      },
    },
    '&>img:nth-of-type(2)': {
      // height: 'calc(100% - 60px)',
      position: 'absolute',
      left: '-2px',
      top: '-37px',
      height: '68px',
      width: '20px',
    },
  },
  total_message: {
    fontWeight: 700,
    color: '#262626',
    padding: '0 2rem',
    '&>span': {
      color: '#0078FF',
    },
  },
  border: {
    borderRight: '1px solid #DCE1E7',
  },
  topic_style: {
    borderRadius: '10px',
    fontWeight: 500,
    fontSize: '12px',
    padding: '4px 8px',
    width: '50px',
    height: '24px',
  },
  modalImg: {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    boxShadow: '0 0 12px 0 rgba(0, 0, 0, 0.25)',
    border: 'none',
    boxSizing: 'border-box',
    '&>img': {
      height: '550px',
      // width: '550px',
    },
  },
})

const CustomerServiceCenter = () => {
  const classes = useStyles()
  const [content, setContent] = useState('')
  const [conversationActiveId, setConversationActiveId] = useState('')
  const [conversationDetail, setConversationDetail] =
    useState<ConversationDetailType>()

  const [page, setPage] = useState<number>(1)
  const [reload, setReload] = useState(true)
  const dispatch = useAppDispatch()
  const ref = useRef<HTMLDivElement>(null)
  const socketRef = useRef<any>(null)

  const [conversationDetailMessage, setConversationDetailMessage] = useState<ConversationDetailMessageType[]>([])

  const [idSocket, setIdSocket] = useState('')

  const [openImg, setOpenImg] = useState(false)
  const [imgShow, setImgShow] = useState('')


  const listConversation = useAppSelector(selectListData)
  const totalData = useAppSelector(selectTotalData)

  console.log('totalData: ', totalData);
  console.log('page: ', page);
  console.log('listConversation: ', listConversation);

  const handleCloseImg = () => {
    setOpenImg(false)
  }

  const showImg = (image: string) => {
    setImgShow(image)
    setOpenImg(true)
  }

  const createMessage = async () => {
    try {
      setContent('')
      await axiosClient.post(`${MESSAGE}/create`, {
        content: content,
        conversation: conversationActiveId,
      })
    } catch (error) {
      console.log(error)
    }
  }

  const renderTopic = (topic?: TYPE_TOPIC) => {
    return (
      <span
        style={
          topic === TYPE_TOPIC.OTHER_QUESTION
            ? {
              background: '#FFE7E4',
              color: '#FD3535',
              padding: '4px 8px',
              borderRadius: '10px',
            }
            : topic === TYPE_TOPIC.COMPLETE
              ? {
                background: 'rgba(253, 53, 233, 0.1)',
                color: '#FD35E9',
                padding: '4px 8px',
                borderRadius: '10px',
              }
              : {}
        }
      >
        {topic}
      </span>
    )
  }

  const handleClickCard = async (item: ConversationType) => {
    try {
      setConversationActiveId(item._id)
      setReload(true)
      if (item.status === STATUS_CONVERSATION.NOT_SEEN) {
        await axiosClient.patch(`${CONVERSATION}/update/${item._id}/seen`)
          .then(res => {
            dispatch(conversationActions.getList({ params: { _sort: `${TYPE_SORT.CREATED_AT_DESC},${TYPE_SORT.STATUS_AT_ASC}` } }))
            dispatch(globalActions.getList({ params: undefined }))
          })
          .catch(err => {
            console.log(err);
          })
      }
    }
    catch (error) {
      console.log(error)
      dispatch(
        snackBarActions.setStateSnackBar({
          content: 'error',
          type: 'error',
        })
      )
    }
  }

  useEffect(() => {
    dispatch(conversationActions.getList({ params: { page, _sort: `${TYPE_SORT.CREATED_AT_DESC},${TYPE_SORT.STATUS_AT_ASC}` } }))
  }, [dispatch, page])

  useEffect(() => {
    const getDetailConversation = async () => {
      try {
        dispatch(loadingActions.openLoading())
        const data: { data: ConversationDetailType } = await axiosClient.get(
          `${CONVERSATION}/get/${conversationActiveId}`
        )
        setConversationDetail(data.data)
        setConversationDetailMessage(data.data.messages)
        dispatch(loadingActions.loadingSuccess())
        setReload(false)
      } catch (error) {
        console.log(error)
        dispatch(loadingActions.loadingSuccess())
      }
    }
    reload && conversationActiveId && getDetailConversation()
  }, [conversationActiveId, reload, dispatch])

  // useEffect(() => {
  //   listConversation.length = 0 &&
  //     setConversationActiveId(listConversation[0]._id)
  // }, [listConversation])

  // useEffect(() => {
  //   listConversation.length > 0 &&
  //     setConversationActiveId(listConversation[0]._id)
  // }, [listConversation])

  useEffect(() => {
    socketRef.current = io('https://server.gmapps.net', {
      extraHeaders: {
        Authorization: "Bearer " + localStorage.getItem('accessToken'),
      }
    })

    socketRef.current.on('getId', (data: string) => {
      setIdSocket(data)
    })

    socketRef.current.on('createMessage', (dataGot: ConversationDetailMessageType) => {
      setConversationDetailMessage(oldMsgs => [...oldMsgs, dataGot])
    })
    return () => {
      socketRef.current.disconnect();
    };

  }, []);

  useEffect(() => {
    // Scroll to the end of the container when data length updates
    if (ref.current) {
      ref.current.scrollTop = ref.current.scrollHeight;
    }
  }, [conversationDetailMessage.length]);


  return (
    <div className={classes.question_container}>
      <div>
        <div>
          <span>1:1문의</span>
          <span>
            <img src={tuningIcon} alt='' />
            필터
          </span>
        </div>
        <div id='infiniteScroll-conversation'>
          <InfiniteScroll
            dataLength={listConversation.length || 0}
            next={() => setPage(page + 1)}
            hasMore={true}
            loader={<></>}
            scrollableTarget='infiniteScroll-conversation'
          >
            {listConversation.map((item, index) => {
              if (index === 0) {
                console.log('item index 1: ', item);
              }
              return (
                <CardQuestion
                  data={item}
                  conversationActiveId={conversationActiveId}
                  onClick={() => handleClickCard(item)}
                  isSeen={item.status}
                />
              )
            })}
          </InfiniteScroll>
        </div>
      </div>

      {conversationActiveId ?
        <div>
          <div>
            <div>
              <div>
                <img src={conversationDetail?.creator.photo} alt='' />
                <div>
                  <p>
                    {conversationDetail?.creator.username ||
                      `${conversationDetail?.creator.firstName}
                      ${conversationDetail?.creator.lastName}`}
                  </p>
                  <p>연락처: {conversationDetail?.mobileNumber}</p>
                </div>
              </div>
              <span>
                <MoreHorizIcon />
              </span>
            </div>
            <p>
              {/* <span className={classes.topic_style}>
              {renderTopic(conversationDetail?.topic)}
            </span> */}
              {conversationDetail?.title}
            </p>
          </div>
          <div ref={ref}>
            <div>
              <p className={classes.total_message}>
                댓글 <span>({conversationDetail?.messages.length})</span>
              </p>
              <div
                className={classes.message_user_container}
                style={{ margin: 0, border: 'none' }}
              >
                <img src={conversationDetail?.creator?.photo || userDefault} alt='' />
                <div>
                  <p>{conversationDetail?.creator?.firstName} {conversationDetail?.creator?.lastName}</p>
                  <p>
                    {moment(conversationDetail?.createdAt).format('YYYY-MM-DD')}
                  </p>
                  <div>
                    {(conversationDetail?.thumbnail || []).map((item, index) => (
                      <div key={index} onClick={() => showImg(item)}>
                        <img src={item} alt='' />
                      </div>
                    ))}
                  </div>
                  <p>{conversationDetail?.description}</p>
                </div>
                {/* <img src={arrowIcon} alt='' /> */}
              </div>
            </div>
            {conversationDetailMessage &&
              conversationDetailMessage.length > 0 &&
              conversationDetailMessage.map((item, index) => (
                <div
                  className={classes.message_user_container}
                  style={
                    index === conversationDetailMessage.length - 1
                      ? { border: 'none' }
                      : {}
                  }
                >
                  <img
                    style={{ width: '40px', height: '40px' }}
                    src={item.sender?.photo}
                    alt=''
                  />
                  <div>
                    <p>{item.sender?.firstName} {item.sender?.lastName}</p>
                    <p>{moment(item.createdAt).format('YYYY-MM-DD')}</p>
                    <p>{item.content}</p>
                    {/* <img
                        src='https://bedental.vn/wp-content/uploads/2022/12/Anh-Avatar-Doremon-dep-ngau-cute.jpg'
                        alt=''
                      /> */}
                  </div>
                  <img
                    style={
                      index === conversationDetailMessage.length - 1
                        ? { left: '0px' }
                        : {}
                    }
                    src={arrowIcon}
                    alt=''
                  />
                </div>
              ))}
          </div>
          <div>
            {/* <img src={galleryAdd} alt='' /> */}
            <InputBase
              label=''
              placeholder='Type here.......'
              onChange={(e) => {
                setContent(e)
              }}
              value={content}
              createMessage={() => createMessage()}
            />
            <span style={{ margin: '0 0.25rem' }}></span>
            <span
              onClick={() => {
                createMessage()
              }}
            >
              <SendIcon color={content ? '#3B71FE' : ''} />
            </span>
          </div>
        </div> :
        <div className={classes.noneMessage}>
          <img src={noneMessage} alt='' />
          <p>내역이 없습니다</p>
        </div>
      }
      <Modal open={openImg} onClose={handleCloseImg} disableAutoFocus>
        <div className={classes.modalImg}>
          <img src={imgShow} alt='' />
        </div>
      </Modal>
    </div>
  )
}

export default CustomerServiceCenter
