import {makeStyles} from '@material-ui/core'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import React from 'react'
import Menu from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'
import Button from '@material-ui/core/Button'
import Select from '@material-ui/core/Select'

const useStyles = makeStyles({
  select_custom_container: {
    display: 'flex',
    border: '1px solid #D0D5DD',
    borderRadius: '10px',
    fontSize: '14px',
    height: '38px',

    justifyContent: 'space-between',
    // padding: '0 0.5rem',
    padding: '9px 16px',
    alignItems: 'center',
    marginRight: '1rem',
    boxSizing: 'border-box',
  },
})

const SelectCustom = (props: {
  placeholder?: string
  width?: string
  data?: {label: string; value?: string}[]
  value?: string
  onChange?: (e: any) => void
}) => {
  const classes = useStyles()
  return (
    <div>
      <select
        className={classes.select_custom_container}
        name='select'
        id='select'
        onChange={(e) => {
          props.onChange && props.onChange(e.target.value)
        }}
        value={props.value}
      >
        {props.data &&
          props.data.map((item) => (
            <option value={item.value}>{item.label}</option>
          ))}
      </select>
    </div>
  )
}

export default SelectCustom
