import { makeStyles } from '@material-ui/core'
import { ConversationType } from '../../types/conversation.type'
import { STATUS_CONVERSATION, TYPE_TOPIC } from '../../types/enum'

const useStyles = makeStyles({
  card_question_container: {
    boxSizing: 'border-box',
    padding: '1rem',
    background: '#FFFFFF',
    borderRadius: '12px',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'start',
    '&>div': {
      display: 'flex',
      width: '100%',
      justifyContent: 'space-between',
      '&>div:nth-of-type(1)': {
        display: 'flex',
        '&>img': {
          width: '91.33px',
          height: '109.59px',
          boxShadow: '0px 2px 12px rgba(0, 0, 0, 0.12)',
          borderRadius: '8px',
          marginRight: '16px',
          objectFit: 'cover',
        },
        '&>div': {
          display: 'flex',
          flexFlow: 'column',
          justifyContent: 'space-between',
          '&>p:nth-of-type(1)': {
            display: 'flex',
            alignItems: 'center',
            fontWeight: 400,
            fontSize: '16px',
            marginTop: 0,
          },
          '&>div': {
            display: 'flex',
            alignItems: 'center',
            gap: '8px',
            '&>p:nth-of-type(1)': {
              margin: 0,
              padding: 0,
              color: '#70777F',
              fontSize: '14px',
              fontWeight: 400,
            },
            '&>p:nth-of-type(2)': {
              margin: 0,
              padding: 0,
              fontWeight: 500,
              color: '#000000',
              fontSize: '12px',
            },
          },

        },
      },
      '&>div:nth-of-type(2)': {
        width: '16px',
        height: '16px',
        background: '#2B83FE',
        borderRadius: '50%',
      },
    },
    '&>img:nth-of-type(1)': {
      width: '25px',
      height: '25px',
    },
    '&:hover': {
      background: 'rgba(43, 131, 254, 0.1)',
    },
  },

  active: {
    border: '1px solid rgba(43, 131, 254, 0.1)',
  },
})

const CardQuestion = (props: {
  data: ConversationType
  conversationActiveId: string
  onClick?: () => void
  isSeen?: STATUS_CONVERSATION
}) => {
  const classes = useStyles()
  const renderTopic = (topic: TYPE_TOPIC) => {
    return (
      <span
        style={
          topic === TYPE_TOPIC.OTHER_QUESTION
            ? { background: '#FFE7E4', color: '#FD3535' }
            : topic === TYPE_TOPIC.COMPLETE
              ? { background: 'rgba(253, 53, 233, 0.1)', color: '#FD35E9' }
              : {}
        }
      >
        {topic}
      </span>
    )
  }
  return (
    <div
      className={classes.card_question_container}
      style={
        props.data._id === props.conversationActiveId
          ? {
            background: 'rgba(43, 131, 254, 0.1)',
          }
          : {}
      }
      onClick={props.onClick}
    >
      <div>
        <div>
          <img src={props.data.thumbnail} alt='' />
          <div>
            <p>{props.data.title}</p>
            <div>
              <p>연락처: </p>
              <p>{props.data.mobileNumber}</p>
            </div>
          </div>
        </div>
        {props.isSeen === STATUS_CONVERSATION.NOT_SEEN && <div />}
      </div>
    </div>
  )
}

export default CardQuestion
