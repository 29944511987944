import {makeStyles} from '@material-ui/styles'
import React, {ReactNode, useState} from 'react'
import HeaderAdmin from '../../components/headerAdmin'
import Sidebar from '../../components/sidebar'
import {useLocation} from 'react-router-dom'
import {ROUTE} from '../../router/routes'
interface Props {
  children: ReactNode
}

const useStyles = makeStyles({
  container_layout: {
    marginTop: '76px',
  },
  container_admin_layout: {},
})

const AdminLayout: React.FC<Props> = ({children}) => {
  const classes = useStyles()
  const [collapse, setCollapse] = useState<boolean>()
  const location = useLocation()

  const handleHideLayout = () => {
    if (location.pathname === ROUTE.LOGIN) {
      return true
    }
  }

  const checkLayout = () => {
    return (
      <div className={classes.container_layout}>
        {!handleHideLayout() && (
          <HeaderAdmin
            setCollapse={() => {
              setCollapse(!collapse)
            }}
          />
        )}
        <div style={{display: 'flex'}}>
          {!handleHideLayout() && <Sidebar collapse={collapse} />}
          <div style={{padding: '0 2rem', width: '100%'}}>{children}</div>
        </div>
      </div>
    )
  }
  return checkLayout()
}

export default AdminLayout
