import React, {useEffect, useState} from 'react'
import grapesjs from 'grapesjs'
import plugin from 'grapesjs-preset-newsletter'

const Editor = () => {
  const [editor, setEditor] = useState<any>(null)
  function myPlugin(editor: any) {
    editor.BlockManager.add('my-first-block', {
      label:
        '<img src="https://dictionary.cambridge.org/vi/images/thumb/button_noun_002_05071.jpg?version=5.0.312"/>',
      content: '<div class="my-block"><input/></div>',
    })
  }
  useEffect(() => {
    const editor = grapesjs.init({
      container: '#editor',
      plugins: [plugin, myPlugin],
      pluginsOpts: {
        [plugin]: {},
      },
    })
    setEditor(editor)
  }, [])
  return (
    <div>
      <div id='editor'></div>
      <form action=''>
        <input type='text' id='fname' name='fname' value='John' />
        <input type='text' id='lname' name='lname' value='John1' />
        <input type='submit' value='Submit' />
      </form>
      <button
        onClick={() => {
          console.log(editor.getHtml())
        }}
      >
        save
      </button>
    </div>
  )
}

export default Editor
